import React, { Component } from "react";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import FindingImages from "./FindingImages";
import FindingActions from "./FindingActions";
import styles from "./styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoadingBg from "@Components/LoadingBg";
import { showSnackbar } from "@Actions/snackbarActions";
// import Zoom from '@material-ui/core/Zoom';
// import { CDNurl } from '@Configs/globalConfig';
import NoteIcon from "@material-ui/icons/Comment";
import DialogPopup from "@Components/DialogPopup";
import DialogContent from "@material-ui/core/DialogContent";
import { translate } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import WarningSharpIcon from "@material-ui/icons/WarningSharp";

import CompareIcon from "@material-ui/icons/Compare";
// import ExternalLinkIcon from '@material-ui/icons/Launch';
import InfoIcon from "@material-ui/icons/Info";
// import DialogTitle from '@material-ui/core/DialogTitle';
import CompareMedia from "@Components/CompareMedia";
import FindingsService from "@Services/Findings";
import {
  formatDatetime,
  replaceBrTags,
  decodeEntities,
  showMediaTags,
  showMediaNotes,
} from "@Utils/helpers.js";
import { shortenString } from "@Utils/helpers.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
// import { over } from 'lodash';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import allIptcFields from "@Configs/mediaInfoConfig";
import { addDomainFindingNote } from "@Actions/domainsActions";
import { Link } from "react-router-dom";
import Scrollbar from "react-perfect-scrollbar-z";
import "@Styles/scrollbar.css";

class Finding extends Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    this.handleShowSearchtargetCases();
  }

  state = {
    // checked: true,
    popupCompareOpen: false,
    popupInfoOpen: false,
    popupFindingsOpen: false,
    details: null,
    findings: null,
    customerImage: "",
    fundImage: "",

    popupNotesOpen: false,
    notes: [],
    newNote: "",
    noteType: "finding",
    showAllCases: false,
  };

  handleOpenPopupCompare = () => {
    this.props.showLoading();
    this.setState({ popupCompareOpen: true });

    FindingsService.getFindingOverlay(this.props.data)
      .then((overlay) => {
        if (!_.isEmpty(overlay)) {
          this.props.hideLoading();
          this.setState({
            customerImage: overlay.customerImage,
            foundImage: overlay.foundImage,
          });
        } else {
          this.props.hideLoading();
          this.setState({ popupCompareOpen: false });
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_overlay_not_available_please_try_again_later"
            ),
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        this.props.hideLoading();
        this.setState({ popupCompareOpen: false });
        this.props.actionShowSnackbar({
          message: error.message,
          variant: "error",
        });
      });
  };

  handleClosePopupCompare = () => {
    this.setState({ popupCompareOpen: false });
  };

  handleDropdownChange = (event) => {
    this.setState({ noteType: event.target.value }, function () {});
  };

  handleDropdownOpen = () => {
    this.setState({ dropdownOpen: true });
  };

  handleDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleOpenPopupNotes = () => {
    FindingsService.getFindingNotes(this.props.data)
      .then((response) => {
        if (_.isEmpty(response)) response = [];

        this.setState({ notes: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message:
            error.message ||
            this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
        return;
      });
    this.setState({ popupNotesOpen: true });
  };

  handleNotesClose = () => {
    this.setState({ popupNotesOpen: false });
  };

  handleNewNoteChange = (event) => {
    this.setState({
      newNote: event.target.value,
    });
  };

  handleAddNote = () => {
    try {
      if (this.state.newNote.trim() === "") return;

      var newNoteObj = {
        id: new Date(),
        note: this.state.newNote,
        date: new Date().toISOString(),
        owner:
          localStorage.getItem("FirstName") +
          " " +
          localStorage.getItem("LastName"),
        type: this.state.noteType,
      };

      const domainId =
        this.props.data.type === "cases"
          ? this.props.findingsReducer[this.props.data.type][
              this.props.data.caseId
            ].info.domain
          : this.props.findingsReducer[this.props.data.type].info.domainId;

      FindingsService.addFindingNote({
        findingId: this.props.data.findingId,
        note: newNoteObj,
      })
        .then((response) => {
          var newNoteList = this.state.notes;
          newNoteObj.id = response.id;
          newNoteList.unshift(newNoteObj);

          if (domainId) {
            var data = {
              note: newNoteObj,
              domainId: domainId,
            };

            this.props.actionAddDomainFindingNote(data);
          }
          this.setState({ notes: newNoteList, newNote: "" });
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message:
              error.message ||
              this.props.t("_something_went_wrong_please_try_again_later"),
            variant: "error",
          });
          console.log(error);
        });
    } catch (error) {
      this.props.actionShowSnackbar({
        message:
          error.message ||
          this.props.t("_something_went_wrong_please_try_again_later"),
        variant: "error",
      });
      console.log(error);
    }
  };

  handleOpenPopupInfo = () => {
    FindingsService.getFindingsInfo(this.props.data)
      .then((details) => {
        this.setState({ details });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
    this.setState({ popupInfoOpen: true });
  };

  handleClosePopupInfo = () => {
    this.setState({ popupInfoOpen: false });
  };

  handleOpenPopupFindings = () => {
    FindingsService.getFindingFindings(this.props.data)
      .then((findings) => {
        this.setState({ findings: findings });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
    this.setState({ popupFindingsOpen: true });
  };

  handleClosePopupFindings = () => {
    this.setState({ popupFindingsOpen: false });
  };

  componentWillUnmount() {
    // this.setState({checked: false});
  }

  generateTable = (data) => {
    const { classes, t } = this.props;

    if (data === null) {
      return <div></div>;
    }

    return (
      <div className={classes.tblContainerDetails}>
        <Table className={classes.tblDeatils}>
          <TableBody>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                <b>{t("_iptc_search_picture")}</b>
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}></TableCell>
            </TableRow>

            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>Byline</TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.byLine}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Caption/Abstract
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.captionAbstract}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Category
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.category}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>City</TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.city}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  Copyright Notice
                </p>
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.copyrightNotice}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Country/Primary Location Code
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.countryPrimaryLocation}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>Credit</TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.credit}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Date Created
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.dateCreated}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Directory Version
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.directoryVersion}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Headline
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.headline}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Keywords
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.keywords}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Object Name
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.objectName}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Original Transmission Reference
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.originalTransmissionReference}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                State/Province
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.provinceState}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>Source</TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.source}
                </p>
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                Writer/Editor
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                <p style={{ maxWidth: "288px", wordBreak: "break-all" }}>
                  {" "}
                  {data.iptcData.writerEditor}
                </p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };

  generateFindings = (data) => {
    const { classes, t } = this.props;

    if (data == null) {
      return <div></div>;
    }

    return (
      <div className={classes.tblContainerFindings}>
        <Table className={classes.tblFindings}>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow className={classes.rowFindings} key={row.id}>
                  <TableCell className={classes.rowFindingsFrist}>
                    <LazyLoadImage
                      src={row.foundImage}
                      alt=""
                      className={classes.imageStyle}
                    />
                  </TableCell>
                  <TableCell className={classes.rowFindingsSecond}>
                    <div className={classes.linkBlock}>
                      <b>{t("_page_url")}: </b>{" "}
                      <Tooltip
                        title={row.foundImageUrl}
                        placement="top-start"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <a
                          className={classes.popupLink}
                          target="_blank"
                          href={row.foundImageUrl}
                          rel="noopener noreferrer"
                        >
                          {row.foundImageUrl}
                        </a>
                      </Tooltip>
                    </div>
                    <div className={classes.linkBlock}>
                      <b>{t("_image_url")}: </b>{" "}
                      <Tooltip
                        title={row.foundImage}
                        placement="top-start"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <a
                          className={classes.popupLink}
                          target="_blank"
                          href={row.foundImage}
                          rel="noopener noreferrer"
                        >
                          {row.foundImage}
                        </a>
                      </Tooltip>
                    </div>
                    <div className={classes.linkBlock}>
                      <b>{t("_image_last_modified")}: </b>
                      {formatDatetime(row.inUseSince, "short")}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  renderCasesOfSearchImage = () => {
    if (!this.state.cases) return <>...</>;

    var casesWithoutCurrent = this.state.cases
      .filter((x) => x.id !== this.props.data.caseId)
      .sort();

    var maxItems = Math.min(casesWithoutCurrent.length, 4);
    if (this.state.showAllCases) maxItems = casesWithoutCurrent.length; // we do not show the current case
    var retval = [];
    for (var i = 0; i < maxItems; i++) {
      var x = casesWithoutCurrent[i];
      retval.push(
        <>
          <Link to={"/case/" + x.id}>{x.number}</Link>
          {i === maxItems - 1 ? "" : ", "}
          {i === maxItems - 1 &&
            casesWithoutCurrent.length > maxItems &&
            this.state.showAllCases === false && (
              <>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ showAllCases: true })}
                >
                  {", " + this.props.t("_show_more_cases")}
                </span>
              </>
            )}
        </>
      );
    }
    return retval;
  };

  handleShowSearchtargetCases = () => {
    FindingsService.getCasesOfSearchTargetByFindingID(this.props.data.findingId)
      .then((response) => {
        //response = [{id,creator,creationDate,state}]
        // put respnse into state
        this.setState({
          cases: response,
          casesDialogLoading: false,
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        this.setState({
          cases: [],
          casesDialogLoading: false,
        });
        console.log(error);
      });
  };

  render() {
    const { classes, data, t } = this.props;
    // const { checked } = this.state;

    var currData = this.props.findingsReducer[data.type];
    if (data.caseId == null) {
      // trash, new or legal
      currData = currData.results[data.findingId];
    } else {
      // case
      currData = currData[data.caseId].results[data.key];
    }
    data.targetImage = currData.targetImage;
    data.findingDetails = currData;
    data.mediaId = currData.mediaId;

    return (
      // <Zoom in={checked}>

      <div className={classes.root}>
        <Card className={classes.card}>
          <div className={classes.cardContent}>
            <div className={classes.imageLeftSide}>
              <Tooltip
                title={t("_finding_overlay")}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <CompareIcon
                  className={classes.compareIcon}
                  onClick={this.handleOpenPopupCompare}
                />
              </Tooltip>

              <Tooltip
                title={t("_notes")}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                {/* <a href={currData.foundImageUrl} target="_blank" rel="noopener noreferrer" className={classes.externalLinkIcon} > */}
                <NoteIcon
                  onClick={this.handleOpenPopupNotes}
                  className={classes.noteIcon}
                />
                {/* </a> */}
              </Tooltip>

              <Tooltip title={t("_metadata")} placement="top">
                <InfoIcon
                  className={classes.infoIcon}
                  onClick={() => this.handleOpenPopupInfo()}
                />
              </Tooltip>
              {/* { currData.images.count } */}
            </div>

            <div className={classes.imageContainer}>
              <FindingImages imagesData={currData} />
            </div>

            <div className={classes.counter}>
              <p> {this.props.counter}</p>
            </div>

            <div className={classes.detailsContainer}>
              <div>
                <b>
                  <u>{t("_finding_info")}</u>
                </b>
              </div>
              <b>{t("_occurences")}: </b>
              {currData.imageCount === 0 ? (
                <span className={classes.imageCount}>
                  {currData.imageCount}
                </span>
              ) : (
                <span
                  className={classes.imageCount}
                  onClick={this.handleOpenPopupFindings}
                >
                  {currData.imageCount}
                </span>
              )}
              <br />
              {data.caseId !== null ? (
                <div>
                  <b>{t("_image_last_modified")}: </b>
                  {formatDatetime(currData.inUseSince, "short")}
                  <br />
                  <b>{t("_image_first_found")}: </b>
                  {formatDatetime(currData.firstFound, "short")}
                  <br />
                </div>
              ) : (
                <div>
                  <b>{t("_image_last_modified")}: </b>
                  {formatDatetime(currData.inUseSince, "short")}
                  <br />
                  <b>{t("_image_first_found")}: </b>
                  {formatDatetime(currData.firstFound, "short")}
                  <br />
                </div>
              )}
              <b>{t("_page_url")}: </b>{" "}
              <Tooltip
                title={currData.foundPageUrl}
                placement="top-start"
                classes={{ tooltip: classes.tooltip }}
              >
                <a
                  className={classes.popupLink}
                  target="_blank"
                  href={currData.foundPageUrl}
                  rel="noopener noreferrer"
                >
                  {shortenString(currData.foundPageUrl, 60)}
                </a>
              </Tooltip>
              <br />
              <b>{t("_image_url")}: </b>{" "}
              <Tooltip
                title={currData.foundImageUrl}
                placement="top-start"
                classes={{ tooltip: classes.tooltip }}
              >
                <a
                  className={classes.popupLink}
                  target="_blank"
                  href={currData.foundImageUrl}
                  rel="noopener noreferrer"
                >
                  {shortenString(currData.foundImageUrl, 60)}
                </a>
              </Tooltip>
              <br />
              <div className={classes.searchImageDetails}>
                <b>
                  <u>
                    {t("_image_info")}{" "}
                    <span
                      className={
                        currData.searchtargetState === 3
                          ? classes.searchImageDetailsGray
                          : classes.searchImageDetailsRed
                      }
                    >
                      (
                      {currData.searchtargetState === 3
                        ? t("_in_search")
                        : true && (
                            <>
                              <WarningSharpIcon
                                style={{
                                  fill: "red",
                                  marginRight: -3,
                                  marginLeft: -5,
                                  height: 15,
                                  position: "relative",
                                  top: 3,
                                }}
                              />
                              {t("_not_searched")}
                            </>
                          )}
                      )
                    </span>
                  </u>
                </b>
              </div>
              <b>{t("_search_image")}: </b>
              {currData.searchNumber} <br />
              {currData &&
                currData.searchimage_iptc_fields &&
                currData.searchimage_iptc_fields.map((item, index) => (
                  <div key={index + "_" +Math.random().toString()}>
                    <b>
                      {allIptcFields &&
                        allIptcFields[item.id] &&
                        allIptcFields[item.id].name}
                      :{" "}
                    </b>
                    {item.value}
                  </div>
                ))}
              {currData &&
              currData.searchImage_tags &&
              currData.searchImage_tags.length > 0 &&
              showMediaTags() ? (
                <span>
                  <b>{t("_tags")}: </b>{" "}
                  {currData.searchImage_tags.map((x) => x.Name).join(", ")}
                </span>
              ) : (
                <b></b>
              )}
              {currData &&
              currData.latestSearchTargetNote &&
              currData.latestSearchTargetNote.length > 0 &&
              showMediaNotes() ? (
                <span>
                  <b>{t("_last_note")}: </b>{" "}
                  {replaceBrTags(currData.latestSearchTargetNote)}
                </span>
              ) : (
                <b></b>
              )}
              {this.props.data.caseId && (
                <div style={{ marginTop: "4px" }}>
                  <span>
                    <u>
                      <b>{t("_cases_of_Image")}:</b>
                    </u>{" "}
                    {this.renderCasesOfSearchImage()}
                  </span>
                </div>
              )}
            </div>

            <div className={classes.buttonsContainer}>
              <FindingActions
                status={this.props.caseState}
                caseId={this.props.data.caseId}
                findingId={this.props.data.findingId}
                data={data}
              />
            </div>
          </div>
        </Card>
        <br />

        <DialogPopup
          open={this.state.popupCompareOpen}
          onClose={this.handleClosePopupCompare}
        >
          <DialogContent classes={{ root: classes.noPadding }}>
            {this.state.customerImage ? (
              <CompareMedia
                image2={this.state.foundImage}
                image1={this.state.customerImage}
              />
            ) : (
              <div className={classes.imageLoadingContainer}>
                <LoadingBg pageType="domain" message={""} animated={true} />
              </div>
            )}
          </DialogContent>
        </DialogPopup>

        <DialogPopup
          open={this.state.popupInfoOpen}
          onClose={this.handleClosePopupInfo}
        >
          <DialogContent>
            {this.generateTable(this.state.details)}
          </DialogContent>
        </DialogPopup>

        <DialogPopup
          open={this.state.popupFindingsOpen}
          onClose={this.handleClosePopupFindings}
        >
          <DialogContent>
            {this.generateFindings(this.state.findings)}
          </DialogContent>
        </DialogPopup>

        <DialogPopup
          open={this.state.popupNotesOpen}
          onClose={this.handleNotesClose}
        >
          <DialogTitle>{t("_notes")}</DialogTitle>
          <DialogContent className={classes.contentContainer}>
            <Scrollbar maxHeight="500px">
              <FormControl className={classes.formControl} fullWidth>
                <Select
                  className={classes.selectInput}
                  open={this.state.dropdownOpen}
                  onClose={this.handleDropdownClose}
                  onOpen={this.handleDropdownOpen}
                  value={this.state.noteType}
                  onChange={this.handleDropdownChange}
                  inputProps={{
                    name: "noteType",
                    id: "noteType-select",
                  }}
                >
                  {["domain", "finding", "media"].map((row, index) => {
                    return (
                      <MenuItem key={index + "_"+Math.random().toString()} value={row}>
                        {t("_" + row)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <br />
              <br />

              <Table className={classes.table}>
                <TableBody>
                  {this.state.notes
                    .filter((item) => item.type === this.state.noteType)
                    .map((row) => {
                      return (
                        <TableRow className={classes.row} key={row.id}>
                          <TableCell>
                            <b>{row.type && row.type + ": "}</b>{" "}
                            {decodeEntities(replaceBrTags(row.note))}
                            <p className={classes.noteTitle}>
                              {row.owner} - {formatDatetime(row.date, "short")}
                            </p>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Scrollbar>
          </DialogContent>
          {window.location.href.indexOf("case") === -1 &&
          !(
            this.props.searchReducer != null &&
            this.props.searchReducer.search != null &&
            this.props.searchReducer.search.class != null &&
            this.props.searchReducer.search.class.name === "case"
          ) ? (
            <DialogActions className={classes.actionSection}>
              <Input
                className={classes.tbAddNote}
                fullWidth
                placeholder={t("_add_notes")}
                value={this.state.newNote}
                id="note"
                onChange={this.handleNewNoteChange}
                onKeyDown={this.keyPress}
              />
              <Button
                onClick={this.handleAddNote}
                color="primary"
                className={classes.btnAddNote}
              >
                {t("_add_note")}
              </Button>
            </DialogActions>
          ) : (
            <></>
          )}
        </DialogPopup>
      </div>
      // </Zoom>
    );
  }
}

Finding.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    findingsReducer: state.findingsReducer,
    searchReducer: state.searchReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      actionAddDomainFindingNote: addDomainFindingNote,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(Finding)
  )
);
