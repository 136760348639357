import React, { Component } from "react";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { unAuthenticate } from "@Actions/authActions";
import { removeDomains } from "@Actions/domainsActions";
import { removeCases } from "@Actions/casesActions";
import { removeMedia } from "@Actions/mediaActions";
import { initCurrentFilterType } from "@Actions/filterActions";
import { setNavbar1, setNavbar2, setNavbar3 } from "@Actions/navbarActions";
import { setToolbar } from "@Actions/toolbarActions";
import TextField from "@material-ui/core/TextField";

import LinearProgress from "@material-ui/core/LinearProgress";
import LangSelector from "./components/LangSelector";
import AvatarChange from "./components/AvatarChange";
import PasswordChange from "./components/PasswordChange";
import MediaInfoSelector from "./components/MediaInfoSelector";
import WhitelistSettings from "./components/WhitelistSettings";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { showSnackbar } from "@Actions/snackbarActions";
import { removeSearch } from "@Actions/searchActions";
import { removeAllTags } from "@Actions/tagsActions";

import { logout } from "@Utils/helpers.js";
import UserService from "@Services/User";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import Scrollbar from "react-perfect-scrollbar-z";

class Setting extends Component {
  state = {
    checkedARL: true,
    firstName: "", // localStorage.getItem("FirstName"),
    lastName: "", // localStorage.getItem("LastName"),
    isUpdating: false,
  };

  handleUpdateInfo = () => {
    if (
      this.state.firstName.trim() === "" ||
      this.state.lastName.trim() === ""
    ) {
      this.props.actionShowSnackbar({
        message: "Please fill all the fields!",
        variant: "warning",
      });
      return;
    }

    var data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      avatarUrl: "#",
      allowRemoteAccess: this.state.checkedARL,
    };

    this.setState({ isUpdating: true });
    UserService.updateInfo(data)
      .then((response) => {
        this.setState({ isUpdating: false });
        localStorage.setItem("FirstName", this.state.firstName);
        localStorage.setItem("LastName", this.state.lastName);
        this.props.actionShowSnackbar({
          message: "Your info updated Successfully!",
          variant: "success",
        });
      })
      .catch((error) => {
        this.setState({
          isUpdating: false,
          firstName: localStorage.getItem("FirstName"),
          lastName: localStorage.getItem("LastName"),
        });
        this.props.actionShowSnackbar({
          message: error.message,
          variant: "error",
        });
      });
  };

  handleTextFieldChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentDidMount() {
    UserService.getInfo()
      .then((response) => {
        this.setState({
          checkedARL: response.allowRemoteAccess,
          firstName: response.firstName,
          lastName: response.lastName,
        });
      })
      .catch((error) => {
        this.setState({ isUpdating: false });
        // this.props.actionShowSnackbar({ message: error.message, variant: "error" });
      });

    this.props.actionRemoveSearch();

    this.props.hideLoading();
    this.props.showLoading();
    this.props.setToolbar(false);
    this.props.actionInitCurrentFilterType("0");
    this.props.setNavbar1({ name: this.props.t("_setting"), link: "/setting" });
    this.props.setNavbar2(null);
    this.props.setNavbar3(null);

    setTimeout(() => this.props.hideLoading(), 200);
    window.scrollTo(0, 0);
  }

  logoutClicked() {
    logout();
    this.props.actionUnAuthenticate();
    this.props.actionRemoveCases();
    this.props.actionRemoveDomains();
    this.props.actionRemoveMedia();
    this.props.actionRemoveAllTags();
  }

  render() {
    const { classes, t } = this.props;

    return (
      <Scrollbar maxHeight="calc(100vh - 175px)">
        <div className={classes.root}>
          <div className={classes.leftside}>
            <Paper className={classes.paper300} elevation={0}>
              <AvatarChange />
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => this.logoutClicked()}
                className={classes.logoutBtn}
              >
                {t("_logout")}
              </Button>
            </Paper>
          </div>
          <div className={classes.rightside}>
            <Paper className={classes.paper} elevation={0}>
              <div className={classes.flexColumns}>
                <div className={classes.w200}>{t("_change_language")}</div>

                <div xs={8}>
                  <LangSelector />
                </div>
              </div>
            </Paper>

            <Paper className={classes.paper} elevation={0}>
              <div className={classes.flexColumns}>
                <div className={classes.w200}>{t("_basic_information")}</div>
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <TextField
                      value={this.state.firstName}
                      fullWidth
                      id="firstName"
                      label={t("_first_name")}
                      className={classes.textField}
                      margin="dense"
                      type="text"
                      onChange={this.handleTextFieldChange("firstName")}
                    />
                    <TextField
                      value={this.state.lastName}
                      fullWidth
                      id="lastName"
                      label={t("_last_name")}
                      className={classes.textField}
                      margin="dense"
                      type="text"
                      onChange={this.handleTextFieldChange("lastName")}
                    />
                    <br />
                    <FormControlLabel
                      classes={{ label: classes.switchLabel }}
                      control={
                        <Switch
                          color="primary"
                          className={classes.switch}
                          classes={{
                            checked: classes.checked,
                            bar: classes.bar,
                          }}
                          // disabled={this.state.isCreating ? true : false }
                          checked={this.state.checkedARL}
                          onChange={(event, checked) =>
                            this.setState({ checkedARL: checked })
                          }
                        />
                      }
                      label={t("_allow_login_by_pixray_customer_support_team")}
                    />

                    <LinearProgress
                      className={classes.updateProgress}
                      color="secondary"
                      style={{
                        visibility: this.state.isUpdating
                          ? "visible"
                          : "hidden",
                      }}
                    />
                    <Button
                      onClick={this.handleUpdateInfo}
                      color="primary"
                      className={classes.updateInfoBtn}
                    >
                      {t("_update_information")}
                    </Button>
                  </div>
                </div>
              </div>
            </Paper>

            <Paper className={classes.paper} elevation={0}>
              <div className={classes.flexColumns}>
                <div className={classes.w200}>{t("_credentials")}</div>
                <div style={{ width: "100%" }}>
                  <PasswordChange />
                </div>
              </div>
            </Paper>

            <Paper className={classes.paper} elevation={0}>
              <div className={classes.flexColumns}>
                <div className={classes.w200}>{t("_image_info_config")}</div>
                <div style={{ width: "100%" }}>
                  <br />
                  <br />
                  <MediaInfoSelector />
                </div>
              </div>
            </Paper>

            <Paper className={classes.paper} elevation={0}>
              <div className={classes.flexColumns}>
                <div className={classes.w200}>{t("_WhitelistSettings")}</div>
                <div style={{ width: "100%" }}>
                  <WhitelistSettings />
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </Scrollbar>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      setToolbar,
      setNavbar1,
      setNavbar2,
      setNavbar3,

      actionUnAuthenticate: unAuthenticate,
      actionRemoveCases: removeCases,
      actionRemoveDomains: removeDomains,
      actionRemoveMedia: removeMedia,
      actionRemoveAllTags: removeAllTags,

      actionRemoveSearch: removeSearch,
      actionShowSnackbar: showSnackbar,
      actionInitCurrentFilterType: initCurrentFilterType,
    },
    dispatch
  );
}

export default connect(
  null,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(Setting)));
