import React, { Component } from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { CDNurl } from "@Configs/globalConfig";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./styles";

class CardImages extends Component {
  constructor(props) {
    super();
  }

  calcRowsCols(imageCount) {
    if (imageCount > 13) {
      imageCount = 11;
    }

    var rows = Math.ceil(Math.sqrt(imageCount));
    var cols = rows;

    if (Math.pow(cols, 2) - cols > imageCount) {
      cols = cols - 1;
    }

    return {
      cols: cols,
      rows: rows,
    };
  }

  imageError(e) {
    e.target.src = require("@Assets/images/empty.png");
  }

  render() {
    const { classes, images } = this.props;
    const newImages = images.filter((el) => !el.statusChangeReason);
    // to set the grid properly
    var grid = this.calcRowsCols(newImages.length);

    return (
      <div className={classes.root}>
        {newImages.length === 0 ? (
          <GridList cellHeight={336} className={classes.gridList} cols={1}>
            <GridListTile
              key={12}
              cols={1}
              classes={{ tile: classes.tileStyle }}
            >
              <LazyLoadImage
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                onError={this.imageError}
                src={require("@Assets/images/empty-big.png")}
                width="315"
                height="350"
              />
            </GridListTile>
          </GridList>
        ) : newImages.length === 1 ? (
          <GridList cellHeight={336} className={classes.gridList} cols={1}>
            <GridListTile
              key={12}
              cols={1}
              classes={{ tile: classes.tileStyle }}
            >
              <LazyLoadImage
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                onError={this.imageError}
                src={
                  CDNurl +
                  newImages[0].foundImage +
                  "?h=336&t=" +
                  localStorage.getItem("Token")
                }
              />
            </GridListTile>
          </GridList>
        ) : (
          <GridList
            cellHeight={336 / grid.rows}
            className={classes.gridList}
            cols={grid.cols}
          >
            {newImages.slice(0, 11).map((tile, index) => (
              <GridListTile
                key={index + "_" +Math.random().toString()}
                cols={1}
                classes={{ tile: classes.tileStyle }}
              >
                <LazyLoadImage
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  onError={this.imageError}
                  src={
                    CDNurl +
                    tile.foundImage +
                    "?h=160&t=" +
                    localStorage.getItem("Token")
                  }
                />
              </GridListTile>
            ))}
            {newImages.length > 12 ? (
              <GridListTile
                key={12}
                cols={1}
                classes={{ tile: classes.tileStyle }}
              >
                <img
                  src={require("@Assets/images/more.png")}
                  alt=""
                  className={classes.imageStyle}
                />
              </GridListTile>
            ) : (
              ""
            )}
          </GridList>
        )}
      </div>
    );
  }
}

CardImages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(CardImages));
