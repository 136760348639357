const Config = {
  case: {
    POST_LICENCING_BUTTON: "case_status_4",
    TERMINATE_CASE_BUTTON: "case_status_11",
    ACTIVATE_CASE_BUTTON: "case_status_5",
    PUSH_TO_DOCUMENTATION: "case_status_2",
    ACCOUNTING_POST_LICENCING: "case_status_7",
    LEGAL_ACTION_BUTTON: "case_status_4",

    CASE_START_BUTTON: "case_status_1",
    CASE_CONFIRM_BUTTON: "case_status_4",
  },
  domain: {
    CASE_CREATION_BUTTON: "domain_status_1",
    REVERT_DOMAIN_BUTTON: "domain_status_1",
    LEGAL_BUTTON: "domain_status_2",
    WHITELIST_BUTTON: "domain_status_3",
    UNCLAIM: "unclaim",
  },
};

export default Config;
