import {
  INIT_FILTERS,
  INIT_SUBFILTERS,
  ADD_FILTER,
  REMOVE_FILTER,
  UPDATE_FILTER,
  UPDATE_CURRENT_FILTER_OIGINAL_DATA,
  REVERT_FILTER_DATA,
  UPDATE_FILTER_NAME,
  UPDATE_FILTER_ALLORANY,
  UPDATE_FILTER_ISPRIVATE,
  ADD_FILTER_RULE,
  REMOVE_FILTER_RULE,
  UPDATE_FITER_RULE_SUBFILTER,
  UPDATE_FITER_RULE_OPERATION,
  UPDATE_FITER_RULE_VALUE,
  INIT_CURRENT_FILTER_ID,
  UPDATE_CURRENT_FILTER_ID,
  UPDATE_SEARCH_FILTER_ID,
  INIT_CURRENT_FILTER_TYPE,
  UPDATE_SAVED,
  UPDATE_ISNEW,
  UPDATE_FILTER_COUNT,
  SWAP_FILTER_ORDER,
  REORDER_FILTERS,
  REMOVE_CLAIM_DOMAIN,
} from "@Types/actionTypes";

export function initFilters(data) {
  return {
    type: INIT_FILTERS,
    payload: data,
  };
}

export function initSubfilters(data) {
  return {
    type: INIT_SUBFILTERS,
    payload: data,
  };
}

export function addFilter(data) {
  return {
    type: ADD_FILTER,
    payload: data,
  };
}

export function removeFilter(data) {
  return {
    type: REMOVE_FILTER,
    payload: data,
  };
}

export function updateCurrentFilterOriginalData(data) {
  return {
    type: UPDATE_CURRENT_FILTER_OIGINAL_DATA,
    payload: data,
  };
}

export function revertFilterData() {
  return {
    type: REVERT_FILTER_DATA,
    payload: null,
  };
}

export function updateSaved(data) {
  return {
    type: UPDATE_SAVED,
    payload: data,
  };
}

export function updateIsNew(data) {
  return {
    type: UPDATE_ISNEW,
    payload: data,
  };
}

export function updateFilter(data) {
  return {
    type: UPDATE_FILTER,
    payload: data,
  };
}

export function updateFilterName(data) {
  return {
    type: UPDATE_FILTER_NAME,
    payload: data,
  };
}

export function updateFilterAllorany(data) {
  return {
    type: UPDATE_FILTER_ALLORANY,
    payload: data,
  };
}

export function updateFilterIsPrivate(data) {
  return {
    type: UPDATE_FILTER_ISPRIVATE,
    payload: data,
  };
}

export function addFilterRule(data) {
  return {
    type: ADD_FILTER_RULE,
    payload: data,
  };
}

export function removeFilterRule(data) {
  return {
    type: REMOVE_FILTER_RULE,
    payload: data,
  };
}

export function updateFilterRuleSubfilter(data) {
  return {
    type: UPDATE_FITER_RULE_SUBFILTER,
    payload: data,
  };
}

export function updateFilterRuleOperation(data) {
  return {
    type: UPDATE_FITER_RULE_OPERATION,
    payload: data,
  };
}

export function updateFilterRuleValue(data) {
  return {
    type: UPDATE_FITER_RULE_VALUE,
    payload: data,
  };
}

export function initCurrentFilterId() {
  return {
    type: INIT_CURRENT_FILTER_ID,
    payload: null,
  };
}

export function updateCurrentFilterId(data) {
  return {
    type: UPDATE_CURRENT_FILTER_ID,
    payload: data,
  };
}

export function updateSearchFilterId(data) {
  return {
    type: UPDATE_SEARCH_FILTER_ID,
    payload: data,
  };
}

export function initCurrentFilterType(data) {
  return {
    type: INIT_CURRENT_FILTER_TYPE,
    payload: data,
  };
}

export function updateFilterCount(data) {
  return {
    type: UPDATE_FILTER_COUNT,
    payload: data,
  };
}

export function swapFilterOrder(data) {
  return {
    type: SWAP_FILTER_ORDER,
    payload: data,
  };
}

export function reorderFilters(data) {
  return {
    type: REORDER_FILTERS,
    payload: data,
  };
}

export function removeClaimDomain(data) {
  return {
    type: REMOVE_CLAIM_DOMAIN,
    payload: data,
  };
}
