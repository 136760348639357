import React, { Component } from "react";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import Base64 from "base-64";
import _ from "lodash";
import { showSnackbar } from "@Actions/snackbarActions";
import { withRouter } from "react-router-dom";
import {
  initFilters,
  initCurrentFilterId,
  updateCurrentFilterId,
  updateSearchFilterId,
  addFilter,
  removeFilter,
  updateCurrentFilterOriginalData,
  updateSaved,
  revertFilterData,
  updateIsNew,
  updateFilterCount,
} from "@Actions/filterActions";
import {
  shortenString,
  updatedSelectedFilters,
  getSelectedFilters,
  resetAllScroll,
  hasFilterEmptyValues,
  hasFilterDuplicateName,
  isClaimFilter,
} from "@Utils/helpers";
import { getDomains, removeDomains } from "@Actions/domainsActions";
import { getMedia, removeMedia } from "@Actions/mediaActions";
import { getCases, removeCases } from "@Actions/casesActions";
import DomainsService from "@Services/Domains";
import CasesService from "@Services/Cases";
import MediaService from "@Services/Media";
import UserService from "@Services/User";
import { getDomainsSearch } from "@Actions/domainsActions";
import { getCasesSearch } from "@Actions/casesActions";
import { getMediaSearch } from "@Actions/mediaActions";
import styles from "./styles";
import {
  getAllCaseTags,
  getAllMediaTags,
  getAllDomainTags,
} from "@Actions/tagsActions";

import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import FilterList from "./FilterList";
import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FilterCreator from "./FilterCreateor";
import LinearProgress from "@material-ui/core/LinearProgress";
import Scrollbar from "react-perfect-scrollbar-z";
import "@Styles/scrollbar.css";

class FilterNavigation extends Component {
  constructor(props) {
    super();
    this.state = {
      isTimerExecuted: false,
      allItemsCount: "...",

      filtersOpen: false,
      isCreating: false,
      openSave: false,
      actionType: "",
      redirect: false,
      anchorEl: null,

      filterIdBeforeEdit: null,
    };
  }

  componentDidMount = () => {
    var intervalId = setInterval(this.timer, 60000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
    this.getAllItemsCount();
  };

  getAllItemsCount = async () => {
    var retval = null;
    if (this.props.navbarReducer.navbar1.link.includes("/domains"))
      retval = await DomainsService.getAllDomainsCount();
    if (this.props.navbarReducer.navbar1.link.includes("/cases"))
      retval = await CasesService.getAllCasesCount();
    if (this.props.navbarReducer.navbar1.link.includes("/media"))
      retval = await MediaService.getAllMediaCount();

    if (retval) this.setState({ allItemsCount: retval.count });
  };

  componentWillUnmount = () => {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  };

  timer = () => {
    var that = this;
    if (!this.props.filterReducer || !this.props.filterReducer.filters) return;
    if (this.state.isTimerExecuted === false) {
      this.setState({ isTimerExecuted: true });
      const ooo = this.props.filterReducer.filters;
      const arrayOfObj = Object.entries(ooo).map((e) => {
        return ooo[e[0]];
      });
      var filters = arrayOfObj.sort((a, b) => {
        if (!a) return 0;
        if (!b) return 0;
        var res =
          parseInt(a.resultCountLastUpdate) - parseInt(b.resultCountLastUpdate);
        return res;
      });
      if (!filters || filters.length === 0) return;
      var filter = filters[0];

      if (
        // check for valid guid. filters in which are currently created do not have guid
        /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
          filter.id
        )
      ) {
        UserService.getFilterCount(filter.id)
          .then((response) => {
            that.props.actionUpdateFilterCount({
              id: filter.id,
              count: response.count,
            });
            that.setState({ isTimerExecuted: false });
          })
          .catch((error) => {
            console.warn("Was not able to update filter count", error);
            that.setState({ isTimerExecuted: false });
          });
      } else {
        that.setState({ isTimerExecuted: false });
      }
    }
  };

  handleListItemMouseOver = (fid) => {
    var tmpObj = {};
    tmpObj["dots_of_" + fid] = true;
    this.setState(tmpObj);
  };

  handleListItemMouseLeave = (fid) => {
    var tmpObj = {};
    tmpObj["dots_of_" + fid] = false;
    this.setState(tmpObj);
  };

  handleDotsClicked = (event, filterid) => {
    event.stopPropagation();
    // save current filter in state. to update selection when no new filter is applied.
    this.setState({
      filterIdBeforeEdit: this.props.filterReducer.currentFilterId,
    });
    this.handleFiltersOpen();
    this.props.actionUpdateCurrentFilterId(filterid);
    this.props.actionUpdateCurrentFilterOriginalData(filterid);
    this.forceUpdate();
  };

  populateList = () => {
    const { classes } = this.props;
    var filterList = [];
    if (this.lastUrlPath !== this.props.navbarReducer.navbar1.link)
      this.getAllItemsCount();
    this.lastUrlPath = this.props.navbarReducer.navbar1.link;
    this.totalFilters = 0;
    if (
      this.props.filterReducer.claims.length > 0 &&
      window.location.href.indexOf("domains") >= 0
    ) {
      filterList.push(
        <MenuItem
          className={classes.menuItemClaim}
          onMouseOver={() => this.handleListItemMouseOver("claim")}
          onMouseLeave={() => this.handleListItemMouseLeave("claim")}
          key={"claimitem"}
          selected={
            isClaimFilter(this.props.filterReducer.currentFilterId) ? true : false
          }
          onClick={() => this.handleApplyFilterById("claim")}
        >
          <ListItemText
            primary={this.renderFilterItemText(
              "claim",
              this.props.filterReducer.claims.length,
              true
            )}
            classes={{
              primary: classes.primary,
              secondary: classes.secondary,
            }}
          />
          <MoreVert
            style={{
              marginLeft: -24,
              visibility: "hidden",
            }}
          ></MoreVert>
        </MenuItem>
      );
    }
    _.forEach(
      this.props.filterReducer.filters,
      function (filter, i) {
        if (this.props.filterReducer.currentFilterType === filter.type) {
          this.totalFilters++;
          filterList.push(
            <MenuItem
              className={classes.menuItem}
              onMouseOver={() => this.handleListItemMouseOver(filter.id)}
              onMouseLeave={() => this.handleListItemMouseLeave(filter.id)}
              key={filter.id + "__filternav"}
              selected={
                this.props.filterReducer.currentFilterId === filter.id
                  ? true
                  : false
              }
              onClick={() => this.handleApplyFilterById(filter.id)}
            >
              <ListItemText
                key={filter.id + "__filternav__listitemtext"}
                primary={this.renderFilterItemText(
                  filter.name,
                  filter.resultCount
                )}
                classes={{
                  primary: classes.primary,
                  secondary: classes.secondary,
                }}
              />
              <MoreVert
                onClick={(ev) => this.handleDotsClicked(ev, filter.id)}
                style={{
                  marginLeft: -24,
                  visibility: this.state["dots_of_" + filter.id]
                    ? "inherit"
                    : "hidden",
                }}
              ></MoreVert>
            </MenuItem>
          );
        }
      }.bind(this)
    );

    return filterList;
  };

  renderFilterItemText = (filtername, filtercount, isCalim) => {
    const { classes, t } = this.props;
    if (isCalim && window.location.href.indexOf("domains") >= 0) {
      return (
        <table>
          <tbody>
            <tr>
              <td className={classes.listItemLeft}>
                <Tooltip
                  title={t("_my_domains")}
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <span className={classes.subTitle}> {t("_my_domains")}</span>
                </Tooltip>
              </td>
              <td className={classes.listItemRigth}>
                {filtercount !== "-1" ? filtercount : "..."}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    return (
      <table>
        <tbody>
          <tr>
            <td className={classes.listItemLeft}>
              <Tooltip
                title={filtername}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <span className={classes.subTitle}>
                  {" "}
                  {shortenString(filtername, 13)}
                </span>
              </Tooltip>
            </td>
            <td className={classes.listItemRigth}>
              {filtercount !== "-1" ? filtercount : "..."}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  isSearch = () => {
    return this.props.navbarReducer.navbar1.link.includes("/search/");
  };

  getApiSearchData = () => {
    if (this.isSearch()) {
      return Base64.encode(
        JSON.stringify({
          class: this.props.searchReducer.search.class.value,
          subclass: this.props.searchReducer.search.subclass.value,
          value: this.props.searchReducer.search.value,
        })
      );
    } else {
      return null;
    }
  };

  handleApplyFilterById = (id) => {
    this.props.actionUpdateCurrentFilterId(id);

    this.props.actionShowSnackbar({
      message: this.props.t("_filter_applied_successfully") + "!",
      variant: "success",
    });
    if (this.isSearch()) {
      this.props.actionUpdateSearchFilterId(
        this.props.filterReducer.currentFilterId
      );
    } else {
      updatedSelectedFilters(this.props.filterReducer.currentFilterType, id);
    }

    setTimeout(() => {
      this.updateFilterResults();
    }, 300);
  };

  handleApplyNoFilter = () => {
    if (this.props.filterReducer.saved) {
      this.setState({
        isCreating: false,
        filtersOpen: false,
      });
      this.props.actionShowSnackbar({
        message: this.props.t("_no_filter_selected") + "!",
        variant: "warning",
      });
      this.props.actionUpdateCurrentFilterId("0");
      this.props.actionUpdateIsNew(false);
      this.props.actionUpdateSaved(true);

      this.props.actionUpdateSearchFilterId("0");
      updatedSelectedFilters(this.props.filterReducer.currentFilterType, "0");

      setTimeout(() => {
        this.updateFilterResults();
      }, 300);
    } else {
      this.setState({ openSave: true, actionType: "noFilter" });
    }
  };

  getNoFilterItemName = () => {
    if (this.props.navbarReducer.navbar1.link.includes("/domains"))
      return this.props.t("_All_domains");
    if (this.props.navbarReducer.navbar1.link.includes("/cases"))
      return this.props.t("_All_cases");
    if (this.props.navbarReducer.navbar1.link.includes("/media"))
      return this.props.t("_All_media");
    this.getAllItemsCount();
    return this.props.t("_All");
  };

  updateFilterResults = () => {
    this.props.hideLoading();
    this.props.showLoading();
    resetAllScroll();
    window.scrollTo(0, -300);
    this.getAllItemsCount();
    if (this.isSearch()) {
      var searchClassValue = this.props.searchReducer.search.class.value;
      var searchApiParams = this.getApiSearchData();
      if (searchClassValue === "domain") {
        this.props.actionRemoveDomains();
        DomainsService.getDomainsSearch({
          param: searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetDomainsSearch({ results: response });
            this.props.hideLoading();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else if (searchClassValue === "case") {
        this.props.actionRemoveCases();
        CasesService.getCasesSearch({
          param: searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetCasesSearch({ results: response });
            this.props.hideLoading();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else {
        this.props.actionRemoveMedia();
        MediaService.getMediaSearch({
          param: searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetMediaSearch({ results: response });
            this.props.hideLoading();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      }
    } else {
      if (this.props.filterReducer.currentFilterType === "case") {
        this.props.actionRemoveCases();
        CasesService.getCases({
          pageNum: 1,
          filter: getSelectedFilters()["case"],
        })
          .then((response) => {
            this.props.actionGetCases({ results: response });
            this.props.hideLoading();
            localStorage.setItem("posCases", -150);
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else if (this.props.filterReducer.currentFilterType === "domain") {
        this.props.actionRemoveDomains();
        DomainsService.getDomains({
          pageNum: 1,
          filter: getSelectedFilters()["domain"],
        })
          .then((response) => {
            this.props.actionGetDomains({ results: response });
            this.props.hideLoading();
            localStorage.setItem("posDomains", -150);
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else {
        // media
        this.props.actionRemoveMedia();
        MediaService.getMedia({
          pageNum: 1,
          filter: getSelectedFilters()["media"],
        })
          .then((response) => {
            this.props.actionGetMedia({ results: response });
            this.props.hideLoading();
            localStorage.setItem("posMedia", -150);
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      }
    }
  };

  handleBackToList = (event) => {
    if (this.props.navbarReducer.navbar1.link.includes("/domain")) {
      let path = "/domains?r";
      this.props.history.push(path);
    }
    if (this.props.navbarReducer.navbar1.link.includes("/cases")) {
      let path = "/cases?r";
      this.props.history.push(path);
    }
    if (this.props.navbarReducer.navbar1.link.includes("/media")) {
      let path = "/media/";
      this.props.history.push(path);
    }
  };

  handleFiltersOpen = (event) => {
    if (this.isSearch()) {
      this.props.actionUpdateCurrentFilterId(
        this.props.filterReducer.searchFilterId
      );
    } else {
      this.props.actionInitCurrentFilterId();
    }

    this.props.actionUpdateSaved(true);
    this.setState({ filtersOpen: true });
  };

  handleCancelSave = () => {
    this.setState({ openSave: false, actionType: "" });
  };

  handleFiltersClose = () => {
    if (this.props.filterReducer.saved) {
      this.setState({ filtersOpen: false, isCreating: false });
      if (this.state.filterIdBeforeEdit !== null) {
        this.props.actionUpdateCurrentFilterId(this.state.filterIdBeforeEdit);
        this.props.actionUpdateCurrentFilterOriginalData(
          this.state.filterIdBeforeEdit
        );
        this.forceUpdate();
      }
      this.props.actionUpdateIsNew(false);
      this.props.actionUpdateSaved(true);
      if (this.isSearch()) {
        this.props.actionUpdateCurrentFilterId(
          this.props.filterReducer.searchFilterId
        );
      }
    } else {
      this.setState({ openSave: true, actionType: "close" });
    }
  };

  handleNoSave = () => {
    if (this.props.filterReducer.isNew) {
      var data = {
        filterId: this.props.filterReducer.currentFilterId,
      };
      this.props.actionRemoveFilter(data);
    } else {
      this.props.actionRevertFilterData();
    }
    this.props.actionUpdateIsNew(false);
    this.props.actionUpdateSaved(true);

    this.setState({
      openSave: false,
      isCreating: false,
      filtersOpen: false,
    });

    this.setState({ actionType: "" });
  };

  handleYesSave = () => {
    const { t } = this.props;

    if (
      hasFilterDuplicateName(
        this.props.filterReducer.filters,
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ].name
      )
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t("_duplicate_filter_name"),
        variant: "warning",
      });
      this.setState({ openSave: false });
      return false;
    }

    if (
      hasFilterEmptyValues(
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ]
      )
    ) {
      this.setState({ openSave: false });
      this.props.actionShowSnackbar({
        message: t("_you_have_empty_fields_filter_cannot_be_saved") + "!",
        variant: "warning",
      });
      return false;
    }

    UserService.addFilter(
      this.props.filterReducer.filters[this.props.filterReducer.currentFilterId]
    )
      .then((response) => {
        var newfilterData =
          this.props.filterReducer.filters[
            this.props.filterReducer.currentFilterId
          ];

        let doDeleteOldFilter = false;
        let oldFilterID = null;
        if (
          newfilterData.id.startsWith("new_") === false &&
          newfilterData.id.toUpperCase() !== response.toUpperCase()
        ) {
          // this was an edit (not new filter) so we must delete the old
          doDeleteOldFilter = true;
          oldFilterID = newfilterData.id;
        }

        newfilterData.id = response.toUpperCase();
        this.props.actionRemoveFilter({
          filterId: this.props.filterReducer.currentFilterId,
        });
        this.props.actionAddFilter(newfilterData);

        if (doDeleteOldFilter === true) {
          UserService.removeFilter(oldFilterID)
            .then(() => {})
            .catch((e) => {
              this.props.actionShowSnackbar({
                message: this.props.t("_editing_filter_failed"),
                variant: "warning",
              });
            });
        }

        if (this.state.actionType === "apply") {
          this.props.actionUpdateCurrentFilterId(response.toUpperCase());
          if (this.isSearch()) {
            this.props.actionUpdateSearchFilterId(
              this.props.filterReducer.currentFilterId
            );
          } else {
            updatedSelectedFilters(
              this.props.filterReducer.currentFilterType,
              this.props.filterReducer.currentFilterId
            );
          }
          setTimeout(() => {
            this.updateFilterResults();
          }, 300);
          this.props.actionShowSnackbar({
            message: t("_filter_applied_successfully") + "!",
            variant: "success",
          });
        } else if (this.state.actionType === "noFilter") {
          this.props.actionUpdateCurrentFilterId("0");
          if (this.isSearch()) {
            this.props.actionUpdateSearchFilterId("0");
          } else {
            updatedSelectedFilters(
              this.props.filterReducer.currentFilterType,
              "0"
            );
          }

          setTimeout(() => {
            this.updateFilterResults();
          }, 300);
          this.props.actionShowSnackbar({
            message: this.props.t("_no_filter_selected") + "!",
            variant: "warning",
          });
        } else {
          // close
          if (this.isSearch()) {
            this.props.actionUpdateCurrentFilterId(
              this.props.filterReducer.searchFilterId
            );
          }
        }

        this.props.actionUpdateSaved(true);
        this.props.actionUpdateIsNew(false);
        this.setState({
          openSave: false,
          isCreating: false,
          filtersOpen: false,
          actionType: "",
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message:
            error.message ||
            this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  handleApplyFilterById = (id) => {
    // if we have a before selected filter in state we must remove it here
    if (this.state.filterIdBeforeEdit !== null) {
      this.setState({ filterIdBeforeEdit: null });
    }

    this.props.actionUpdateCurrentFilterId(id);

    this.props.actionShowSnackbar({
      message: this.props.t("_filter_applied_successfully") + "!",
      variant: "success",
    });
    if (this.isSearch()) {
      this.props.actionUpdateSearchFilterId(
        this.props.filterReducer.currentFilterId
      );
    } else {
      updatedSelectedFilters(this.props.filterReducer.currentFilterType, id);
    }

    setTimeout(() => {
      this.updateFilterResults();
    }, 300);
  };

  handleApplyFilter = () => {
    if (this.props.filterReducer.saved) {
      this.setState({
        isCreating: false,
        filtersOpen: false,
      });
      if (this.state.filterIdBeforeEdit !== null) {
        this.setState({ filterIdBeforeEdit: null });
      }
      this.props.actionShowSnackbar({
        message: this.props.t("_filter_applied_successfully") + "!",
        variant: "success",
      });
      this.props.actionUpdateSaved(true);
      this.props.actionUpdateIsNew(false);
      if (this.isSearch()) {
        this.props.actionUpdateSearchFilterId(
          this.props.filterReducer.currentFilterId
        );
      } else {
        updatedSelectedFilters(
          this.props.filterReducer.currentFilterType,
          this.props.filterReducer.currentFilterId
        );
      }

      setTimeout(() => {
        this.updateFilterResults();
      }, 300);
    } else {
      this.setState({ openSave: true, actionType: "apply" });
    }
  };

  render() {
    const { classes, t } = this.props;
    const { anchorEl } = this.state;
    return (
      <>
        {(this.props.navbarReducer.navbar1 &&
          this.props.navbarReducer.navbar1.link &&
          this.props.navbarReducer.navbar1.link === "/setting") === false &&
          this.props.navbarReducer.navbar2 === null && (
            <Paper id="filters_nav_title" className={classes.title}>
              <div className={classes.title_header}>{t("_filterNavTitle")}</div>
              <div
                onClick={() => this.handleFiltersOpen()}
                className={classes.title_add_Filter}
              >
                +&nbsp;{t("_add_new_filter")}
              </div>
            </Paper>
          )}
        {this.props.navbarReducer.navbar2 != null && (
          // Above means when in details hide Title. We add back to list button here
          <Paper id="filters_nav_title" className={classes.title}>
            <div
              className={classes.title_header_clickable}
              onClick={() => this.handleBackToList()}
            >
              {t("_show_less")}
            </div>
          </Paper>
        )}
        <Paper id="filters-nav-menu" className={classes.menu}>
          <Scrollbar
            effectData={this.props.filterReducer.filters}
            maxHeight="74vh"
          >
            {this.isSearch() === false &&
              this.props.filterReducer.currentFilterType !== "0" && (
                <MenuItem
                  className={classes.menuItem}
                  key={"defaultFilterID"}
                  selected={
                    this.props.filterReducer.currentFilterId === null
                      ? true
                      : false
                  }
                  onClick={() => this.handleApplyNoFilter()}
                >
                  <ListItemText
                    primary={this.renderFilterItemText(
                      this.getNoFilterItemName(),
                      this.state.allItemsCount
                    )}
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secondary,
                    }}
                  />
                </MenuItem>
              )}
            {this.populateList()}
          </Scrollbar>
        </Paper>

        <Menu
          id="filters-menu"
          className={classes.menu}
          classes={{ paper: classes.menuPaper }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseFiltersDropdown}
        >
          <div className={classes.menuItemButton}>
            <Button
              onClick={this.handleFiltersOpen}
              variant="contained"
              className={classes.addNewFilterButton}
              color="primary"
              size="medium"
            >
              <AddIcon className={classes.rightIcon} />
              {t("_add_new_filter")}
            </Button>
          </div>
          {this.populateList()}

          {/* {
            SearchClassesConfig.map((row, index) => {
              return (
                <MenuItem className={classes.menuItem} onClick={() => this.handleSelectClass(row)}  key={index}>
                  <ListItemText classes={{ primary: classes.primary }} inset primary={t("_" + row.display)} />
                </MenuItem>
              );
            })
          } */}
        </Menu>

        <DialogPopup
          classes={{ paper: classes.filterDialogPaper }}
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.filtersOpen}
          onClose={this.handleFiltersClose}
        >
          <DialogTitle>{t("_filters")}</DialogTitle>
          <DialogContent classes={{ root: classes.filterDialogContentRoot }}>
            <Paper className={classes.filterContentContainer} elevation={0}>
              <FilterList />
              <FilterCreator />
            </Paper>
            <LinearProgress
              className={classes.createProgress}
              color="secondary"
              style={{
                visibility: this.state.isCreating ? "visible" : "hidden",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleFiltersClose}
              color="default"
              disabled={this.state.isCreating ? true : false}
            >
              {t("_close")}
            </Button>

            {this.props.filterReducer.currentFilterId == null ||
            this.props.filterReducer.currentFilterId.toString() === "0" ? (
              <Button
                onClick={this.handleApplyNoFilter}
                color="secondary"
                disabled={this.state.isCreating ? true : false}
              >
                {t("_no_filter")}
              </Button>
            ) : (
              <Button
                onClick={this.handleApplyFilter}
                color="primary"
                disabled={this.state.isCreating}
              >
                {t("_apply")}
              </Button>
            )}
          </DialogActions>
        </DialogPopup>

        <DialogPopup open={this.state.openSave} onClose={this.handleCloseSave}>
          <DialogTitle>{t("_confirmation_required")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_you_have_unsaved_changes_do_you_want_to_save_them")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelSave}>{t("_cancel")}</Button>
            <Button onClick={this.handleNoSave} color="secondary">
              {t("_no")}
            </Button>
            <Button onClick={this.handleYesSave} color="primary">
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterReducer: state.filterReducer,
    domainsReducer: state.domainsReducer,
    mediaReducer: state.mediaReducer,
    casesReducer: state.casesReducer,
    navbarReducer: state.navbarReducer,
    searchReducer: state.searchReducer,
    tagsReducer: state.tagsReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionInitFilters: initFilters,
      actionInitCurrentFilterId: initCurrentFilterId,
      actionUpdateCurrentFilterId: updateCurrentFilterId,
      actionAddFilter: addFilter,
      actionRemoveFilter: removeFilter,
      actionUpdateCurrentFilterOriginalData: updateCurrentFilterOriginalData,
      actionUpdateSaved: updateSaved,
      actionRevertFilterData: revertFilterData,
      actionUpdateIsNew: updateIsNew,
      actionUpdateSearchFilterId: updateSearchFilterId,
      actionUpdateFilterCount: updateFilterCount,

      actionGetDomainsSearch: getDomainsSearch,
      actionGetCasesSearch: getCasesSearch,
      actionGetMediaSearch: getMediaSearch,

      actionGetAllDomainTags: getAllDomainTags,
      actionGetAllCaseTags: getAllCaseTags,
      actionGetAllMediaTags: getAllMediaTags,

      actionGetDomains: getDomains,
      actionRemoveDomains: removeDomains,

      actionGetMedia: getMedia,
      actionRemoveMedia: removeMedia,

      actionGetCases: getCases,
      actionRemoveCases: removeCases,
      actionShowSnackbar: showSnackbar,
      showLoading,
      hideLoading,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(withRouter(FilterNavigation))));
