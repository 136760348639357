import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { withRouter } from "react-router-dom";

import {
  CheckBoxOutlined,
  ChatBubbleOutline,
  InfoOutlined,
  Edit,
} from "@material-ui/icons";

import { Grid, FormControlLabel, Switch, Card } from "@material-ui/core";
import _ from "lodash";

import Scrollbar from "react-perfect-scrollbar-z";
import "@Styles/scrollbar.css";

class CaseTodoMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedFilter: 0,
      onlyOwnCases: false,
    };
    this.routeChange = this.routeChange.bind(this);
  }

  renderFilterItemText = (filtername, filtercount) => {
    const { classes } = this.props;
    return (
      <table>
        <tbody>
          <tr>
            <td className={classes.listItemLeft}>
              <span className={classes.subTitle}> {filtername}</span>
            </td>
            <td className={classes.listItemRigth}>{filtercount}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  handleSelect = (index) => {
    this.setState({ selectedFilter: index });
  };

  onItemCardMouseEnter = (i) => {
    var val = {};
    val["hovered_index_" + i.toString()] = true;
    this.setState(val);
  };

  onItemCardMouseLeave = (i) => {
    var val = {};
    val["hovered_index_" + i.toString()] = false;
    this.setState(val);
  };

  routeChange = (caseid, jumpToMessageEnabled) => {
    let path =
      "/case/" + caseid.toUpperCase() + (jumpToMessageEnabled ? "?m" : "");
    this.props.history.push(path);
    this.props.onClose();
  };

  getSelectionHtml = () => {
    var sel,
      html = "";
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        var frag = sel.getRangeAt(0).cloneContents();
        var el = document.createElement("div");
        el.appendChild(frag);
        html = el.innerHTML;
      }
    } else if (document.selection && document.selection.type === "Text") {
      html = document.selection.createRange().htmlText;
    }
    return html;
  };

  handleCardClicked = (caseid, descriptionText) => {
    if (!(this.getSelectionHtml().length > 0))
      this.routeChange(caseid, descriptionText ? true : false);
  };

  renderItemCard = (
    icon,
    caseid,
    index,
    headerText,
    caseText,
    FooterText,
    descriptionText
  ) => {
    const { classes } = this.props;
    return (
      <Card
        key={"key_for_card" + index.toString()}
        className={index.toString()}
        onMouseOver={() => this.onItemCardMouseEnter(index)}
        onMouseLeave={() => this.onItemCardMouseLeave(index)}
        onClick={() => this.handleCardClicked(caseid, descriptionText)}
      >
        <table>
          <tbody>
            <tr>
              <td className={classes.itemCardIcon}>{icon}</td>
              <td>
                <div className={classes.itemCardHeader}>{headerText}</div>
                <div
                  className={classes.itemCardCase}
                  style={{
                    color: this.state["hovered_index_" + index.toString()]
                      ? "#2680eb"
                      : "#616161",
                  }}
                >
                  {caseText}
                </div>
                {descriptionText && (
                  <div
                    className={classes.itemCardDescription}
                    style={{
                      color: this.state["hovered_index_" + index.toString()]
                        ? "#2680eb"
                        : "#616161",
                    }}
                  >
                    <textarea
                      style={{
                        border: "none",
                        resize: "none",
                        background: "transparent",
                        cursor: "pointer",
                        color: "inherit",
                        width: "100%",
                        height: 60,
                      }}
                      value={descriptionText}
                      readOnly
                    ></textarea>{" "}
                  </div>
                )}
                <div className={classes.itemCardFooter}>{FooterText}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    );
  };

  getFilteredItemList = (showOnlyOwnCases) => {
    var reg = showOnlyOwnCases
      ? new RegExp(
          localStorage.getItem("FirstName") +
            " " +
            localStorage.getItem("LastName")
        )
      : new RegExp(".*");
    switch (this.state.selectedFilter) {
      case 0:
        return this.props.caseTodoReducer.todos.filter((x) =>
          reg.test(x.caseCreator)
        );
      case 1:
        return this.props.caseTodoReducer.todos.filter(
          (x) => x.type === "review" && reg.test(x.caseCreator)
        );
      case 2:
        return this.props.caseTodoReducer.todos.filter(
          (x) => x.type === "draft" && reg.test(x.caseCreator)
        );
      case 3:
        return this.props.caseTodoReducer.todos.filter(
          (x) => x.type === "message" && reg.test(x.caseCreator)
        );
      default:
        break;
    }
  };

  renderItemList = () => {
    const { t } = this.props;

    var itemList = [];
    var filteredItems = this.getFilteredItemList(this.state.onlyOwnCases);
    _.forEach(
      filteredItems,
      function (item, i) {
        switch (item.type) {
          case "review": {
            let headertext = t("_case_status") + ": " + t("_review");
            let casetext =
              t("_casenumber") +
              ": " +
              item.caseNumber +
              " (" +
              item.caseDomain +
              ")";
            let footertext =
              item.caseCreator +
              ", " +
              new Date(item.caseCreationDate).toLocaleString();
            itemList.push(
              this.renderItemCard(
                <InfoOutlined
                  key={item.caseid + "_" + i.toString()}
                ></InfoOutlined>,
                item.caseid,
                i,
                headertext,
                casetext,
                footertext,
                null
              )
            );
            break;
          }
          case "draft": {
            let headertext =
              t("_case_status") +
              ": " +
              t("_draft") +
              " (" +
              t("_for_more_7_days") +
              ")";
            let casetext =
              t("_casenumber") +
              ": " +
              item.caseNumber +
              " (" +
              item.caseDomain +
              ")";
            let footertext =
              item.caseCreator +
              ", " +
              new Date(item.caseCreationDate).toLocaleString();
            itemList.push(
              this.renderItemCard(
                <Edit></Edit>,
                item.caseid,
                i,
                headertext,
                casetext,
                footertext,
                null
              )
            );
            break;
          }
          case "message": {
            let headertext =
              t("_new_message") +
              " " +
              t("_sender") +
              " " +
              item.messageSender +
              "!";
            let casetext =
              t("_casenumber") +
              ": " +
              item.caseNumber +
              " (" +
              item.caseDomain +
              ")";
            let descriptionText = item.messageText;
            let footertext =
              item.caseCreator +
              ", " +
              new Date(item.caseCreationDate).toLocaleString();
            itemList.push(
              this.renderItemCard(
                <ChatBubbleOutline></ChatBubbleOutline>,
                item.caseid,
                i,
                headertext,
                casetext,
                footertext,
                descriptionText
              )
            );
            break;
          }
          default:
            break;
        }
      }.bind(this)
    );

    return itemList;
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, caseTodoReducer, t } = this.props;

    return (
      <Grid
        classes={{ container: classes.rootPaper }}
        container
        direction="row"
      >
        <Grid item className={classes.borderRight}>
          <Grid container direction="column">
            <Grid item className={classes.leftFirst}>
              <CheckBoxOutlined className={classes.mainIcon}></CheckBoxOutlined>
              {t("_case_todos")}
            </Grid>
            <Grid
              item
              style={{
                color: this.state.selectedFilter === 0 ? "#2680eb" : "inherit",
              }}
              className={classes.leftOthers}
              onClick={() => this.handleSelect(0)}
            >
              {this.renderFilterItemText(
                t("_all"),
                caseTodoReducer.todos.length
              )}
            </Grid>
            <Grid
              item
              style={{
                color: this.state.selectedFilter === 1 ? "#2680eb" : "inherit",
              }}
              className={classes.leftOthers}
              onClick={() => this.handleSelect(1)}
            >
              {this.renderFilterItemText(
                t("_review"),
                caseTodoReducer.todos.filter((x) => x.type === "review").length
              )}
            </Grid>
            <Grid
              item
              className={classes.leftOthers}
              style={{
                color: this.state.selectedFilter === 2 ? "#2680eb" : "inherit",
              }}
              onClick={() => this.handleSelect(2)}
            >
              {this.renderFilterItemText(
                t("_draft"),
                caseTodoReducer.todos.filter((x) => x.type === "draft").length
              )}
            </Grid>
            <Grid
              item
              className={classes.leftOthers}
              style={{
                color: this.state.selectedFilter === 3 ? "#2680eb" : "inherit",
              }}
              onClick={() => this.handleSelect(3)}
            >
              {this.renderFilterItemText(
                t("_messages"),
                caseTodoReducer.todos.filter((x) => x.type === "message").length
              )}
            </Grid>
            <Grid item className={classes.leftBottom}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={this.handleChange("onlyOwnCases")}
                    color="default"
                    value="onlyOwnCases"
                  />
                }
                label={t("_only_own_cases")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.right}>
          <Scrollbar
            effectData={this.getFilteredItemList(this.state.onlyOwnCases)}
            options={{ suppressScrollX: true }}
            height="460px"
          >
            {this.renderItemList()}
          </Scrollbar>
        </Grid>
      </Grid>
    );
  }
}

CaseTodoMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    caseTodoReducer: state.caseTodoReducer,
  };
}

export default connect(
  mapStateToProps,
  null
)(translate("translations")(withStyles(styles)(withRouter(CaseTodoMenu))));
