import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { translate } from "react-i18next";
import styles from "./styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CDNurl } from "@Configs/globalConfig";
import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import Scrollbar from "react-perfect-scrollbar-z";
import "@Styles/scrollbar.css";

import { LazyLoadImage } from "react-lazy-load-image-component";

// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Button from "@material-ui/core/Button";

import ExternalLinkIcon from "@material-ui/icons/Launch";
import InfoIcon from "@material-ui/icons/Info";
import NoteIcon from "@material-ui/icons/Comment";
// import DialogTitle from '@material-ui/core/DialogTitle';
import MediaService from "@Services/Media";
import FindingsService from "@Services/Findings";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import Config from "@Configs/mediaStateConfig";
import TagInput from "@Components/TagInput";
import {
  deleteMedia,
  removeMediaTag,
  addMediaTag,
  updateMediaStatus,
} from "@Actions/mediaActions";
import { updateAllMediaTags } from "@Actions/tagsActions";
import { removeDomains } from "@Actions/domainsActions";
import { showSnackbar } from "@Actions/snackbarActions";
import Input from "@material-ui/core/Input";
import {
  formatDatetime,
  replaceBrTags,
  decodeEntities,
} from "@Utils/helpers.js";
import _ from "lodash";

class MediaItem extends Component {
  constructor(props) {
    super();
  }

  state = {
    // checked: true,

    popupFoundDomainsOpen: false,
    foundDomains: null,

    popupInfoOpen: false,
    info: null,

    popupHistoryOpen: false,
    history: null,

    anchorEl: null,

    openDeletePopup: false,
    openDeleteFindingPopup: false,

    deletionResult: {},
    openDeletionResultPopup: false,

    notes: [],
    popupNotesOpen: false,
    newNote: "",
  };

  handleOpenPopupNotes = () => {
    MediaService.getMediaNotes(this.props.mediaId)
      .then((response) => {
        if (_.isEmpty(response)) response = [];

        this.setState({ notes: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
    this.setState({ popupNotesOpen: true });
  };

  handleNotesClose = () => {
    this.setState({ popupNotesOpen: false });
  };

  handleNewNoteChange = (event) => {
    this.setState({
      newNote: event.target.value,
    });
  };

  handleAddNote = () => {
    if (this.state.newNote.trim() === "") return;

    var newNoteObj = {
      id: new Date(),
      note: this.state.newNote,
      date: new Date().toISOString(),
      owner:
        localStorage.getItem("FirstName") +
        " " +
        localStorage.getItem("LastName"),
    };

    MediaService.addMediaNote({ mediaId: this.props.mediaId, note: newNoteObj })
      .then((response) => {
        var newNoteList = this.state.notes;
        newNoteList.unshift(newNoteObj);

        this.setState({ notes: newNoteList, newNote: "" });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  tagsChanged = (actionName, tag) => {
    // console.group('all current Tags');
    // console.log(updatedTags);
    // console.log("---action:");
    // console.log(actionName);
    // console.log("---tag:");
    // console.log(tag);
    // console.groupEnd();

    var data = {
      mediaId: this.props.mediaId,
      tag: tag,
    };

    if (actionName === "create-option") {
      MediaService.addMediaTag(data)
        .then((response) => {
          data.tag.id = response.id;
          this.props.actionAddMediaTag(data);
          this.props.actionUpdateAllMediaTags(data.tag);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    } else if (actionName === "remove-option") {
      MediaService.removeMediaTag(data)
        .then((response) => {
          console.log(response);
          this.props.actionRemoveMediaTag(data);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    } else {
      // select-option
      MediaService.addMediaTag(data)
        .then((response) => {
          console.log(response);
          data.tag.id = response.id;
          this.props.actionAddMediaTag(data);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    }

    return null;
  };

  handleActionClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleActionClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClosePopupFoundDomains = () => {
    this.setState({ popupFoundDomainsOpen: false });
  };

  handleOpenPopupFoundDomains = () => {
    if (this.props.data.numberOfFindings === 0) {
      return;
    }
    MediaService.getMediaDomains({ mediaId: this.props.mediaId })
      .then((foundDomains) => {
        this.setState({ foundDomains });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
    this.setState({ popupFoundDomainsOpen: true });
  };

  handleOpenPopupInfo = () => {
    MediaService.getMediaInfo({ mediaId: this.props.mediaId })
      .then((info) => {
        this.setState({ info });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
    this.setState({ popupInfoOpen: true });
  };
  handleClosePopupInfo = () => {
    this.setState({ popupInfoOpen: false });
  };

  handleOpenPopupHistory = () => {
    MediaService.getMediaHistory({ mediaId: this.props.mediaId })
      .then((history) => {
        this.setState({ history });
        this.setState({ popupHistoryOpen: true });
      })
      .catch((error) => {
        console.log(error);
        this.props.actionShowSnackbar({ message: error, variant: "error" });
      });
  };
  handleClosePopupHistory = () => {
    this.setState({ popupHistoryOpen: false });
  };

  generateInfoTable = (data) => {
    const { classes, t } = this.props;

    if (data === null) {
      return <div></div>;
    }

    return (
     
      <div className={classes.tblContainerDetails}>
       <Scrollbar maxHeight="500px">
        <Table className={classes.tblDeatils}>
          <TableBody>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                <b>{t("_ipt_data")}</b>
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}></TableCell>
            </TableRow>

            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_by_line")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.byLine}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_caption_abstract")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.captionAbstract}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_category")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.category}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_city")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.city}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_copyright_notice")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.copyrightNotice}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_country_primary_location")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.countryPrimaryLocation}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_credit")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.credit}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_date_created")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.dateCreated}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_directory_version")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.directoryVersion}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_head_line")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.headline}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_keywords")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.keywords}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_object_name")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.objectName}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_original_transmission_reference")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.originalTransmissionReference}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_province_state")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.provinceState}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_source")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.source}
              </TableCell>
            </TableRow>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                {t("_writer_editor")}
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}>
                {data.writerEditor}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        </Scrollbar>
      </div>
    );
  };

  generateHistoryTable = (data) => {
    const { classes, t } = this.props;

    if (data === null) {
      return <div></div>;
    }

    return (
      <div className={classes.tblContainerHistory}>
        <Table className={classes.tblDeatils}>
          <TableBody>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                <b>{t("_history")}</b>
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}></TableCell>
            </TableRow>
            {data.map((item, index) => (
              <TableRow className={classes.rowDeatils} key={index + "_" + Math.random().toString()}>
                <TableCell className={classes.rowDetailsFrist}>
                  <b>
                    <p className={classes.nowrap}>{item.userName} </p>
                  </b>
                </TableCell>
                <TableCell className={classes.rowDetailsFrist}>
                  <b>
                    {item.actionType}: {item.actionValue}
                  </b>
                </TableCell>
                <TableCell className={classes.rowDetailsSecond}>
                  {item.dateCreated}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  generateFoundDomainsTable = (data) => {
    const { classes, t } = this.props;

    if (data === null) {
      return <div></div>;
    }

    return (
      <div className={classes.tblContainerHistory}>
        <Table className={classes.tblDeatils}>
          <TableBody>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                <b>{t("_domains_with_findings_for_this_image")}: </b>
              </TableCell>
            </TableRow>
            {data.map((item) => (
              <TableRow className={classes.rowDeatils} key={item.domainId}>
                <TableCell className={classes.rowDetailsFrist}>
                  <Link
                    to={"/domain/" + item.domainId}
                    className={classes.domainLink}
                  >
                    <b>{item.domainName}</b>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  handleCloseDeletePopup = () => {
    this.setState({ openDeletePopup: false });
  };

  handleCloseDeleteFindingPopup = () => {
    this.setState({ openDeleteFindingPopup: false });
  };

  deleteConfirmClicked = () => {
    MediaService.deleteFindingsAndMedia(this.props.data.id)

      .then((response) => {
        /*
{case_findings: 1
deleted_findings: 2
image_status: "paused"} is rretval
        */
        this.props.actionShowSnackbar({
          message: this.props.t("_image_and_its_findings_deleted_successfully"),
          variant: "success",
        });

        // open deletion info popup
        this.setState({ openDeletionResultPopup: true });
        this.setState({ deletionResult: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  onDeletionResultPopupClose = () => {
    this.setState({ openDeletionResultPopup: false });
    this.setState({ openDeletePopup: false });

    this.props.actionDeleteMedia(this.props.data);
    localStorage.setItem("findingDeleted", "1");
    this.props.actionRemoveDomains();
    this.props.actionMediaUpdateState({
      mediaId: this.props.data.id,
      state: this.state.deletionResult.image_status === "deleted" ? 6 : 8,
    });
  };

  deleteFindingConfirmClicked = () => {
    FindingsService.deleteFindingsByMedia({ mediaId: this.props.data.id })
      .then((response) => {
        this.setState({ openDeleteFindingPopup: false });

        this.props.actionShowSnackbar({
          message: this.props.t("_all_findings_deleted_successfully"),
          variant: "success",
        });

        localStorage.setItem("findingDeleted", "1");
        this.props.actionRemoveDomains();
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  handletakeOutOfSearch = () => {
    MediaService.updateMediaStatus({ mediaId: this.props.data.id, status: 8 })
      .then((response) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_image_taken_out_of_search_successfully"),
          variant: "success",
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
    this.setState({ anchorEl: null });
  };

  handleDeleteImageAndFinding = () => {
    this.setState({ anchorEl: null });
    this.setState({ openDeletePopup: true });
  };

  handleDeleteAllFindings = () => {
    this.setState({ anchorEl: null });
    this.setState({ openDeleteFindingPopup: true });
  };

  componentWillUnmount() {
    this.setState({ checked: false });
  }

  keyPress = (event) => {
    if (event.keyCode === 13) {
      // enter
      this.handleAddNote();
    }
  };

  render() {
    const { classes, data, t } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <div className={classes.cardContent}>
            <div className={classes.imageLeftSide}>
              <a
                href={CDNurl + data.url + "?t=" + localStorage.getItem("Token")}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.externalLinkIcon}
              >
                <ExternalLinkIcon />
              </a>

              <InfoIcon
                className={classes.infoIcon}
                onClick={this.handleOpenPopupInfo}
              />
              <NoteIcon
                className={classes.noteIcon}
                onClick={this.handleOpenPopupNotes}
              />
              {/* { currData.images.count } */}
            </div>

            <div className={classes.imageContainer}>
              {/* <GridList cellHeight={90} className={classes.gridList} cols={1}> */}
              {/* <GridListTile key={1} cols={1} classes={{tile:classes.tileStyle}} > */}
              <LazyLoadImage
                src={
                  CDNurl +
                  data.url +
                  "?h=120&t=" +
                  localStorage.getItem("Token")
                }
                className={classes.imageStyle}
              />
              {/* </GridListTile> */}
              {/* </GridList> */}
            </div>

            <div className={classes.detailsContainer}>
              <b>{t("_file_name")}:</b>
              <br />
              <Tooltip
                title={data.refNumber}
                placement="top-start"
                classes={{ tooltip: classes.tooltip }}
              >
                <span>{data.refNumber}</span>
              </Tooltip>{" "}
              <br />
              <br />
              <b>{t("_uploaded_date")}:</b>
              <br />
              <span title={formatDatetime(data.uploadedOn, "full")}>
                {formatDatetime(data.uploadedOn, "short")}
              </span>
            </div>

            <div className={classes.detailsContainer2}>
              <b>{t("_number_of_findings")}:</b>
              <br />
              <p
                className={classes.numberOfFindingsUrl}
                onClick={this.handleOpenPopupFoundDomains}
              >
                {data.numberOfFindings}
              </p>
              <br />
              <b>{t("_status")}:</b>
              <br />
              <p className={classes.mediaState}>
                {t("_" + Config[data.state].displayName)}
              </p>
            </div>

            <div className={classes.detailsContainer3}>
              <TagInput
                tags={this.props.tagsReducer.mediaTags}
                value={data.tags}
                onTagsChanged={this.tagsChanged}
                isMulti={true}
                isCreatable={true}
              />
            </div>

            <div className={classes.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.actionButton}
                aria-owns={anchorEl ? "actions-menu" : undefined}
                aria-haspopup="true"
                onClick={this.handleActionClick}
              >
                {t("_more_actions")}
              </Button>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleActionClose}
              >
                <MenuItem onClick={this.handletakeOutOfSearch}>
                  {t("_remove_image_from_search")}
                </MenuItem>
                <MenuItem onClick={this.handleDeleteImageAndFinding}>
                  {t("_delete_image_and_findings")}
                </MenuItem>
              </Menu>

              <Button
                variant="contained"
                color="default"
                className={classes.historyButton}
                onClick={this.handleOpenPopupHistory}
              >
                {t("_show_history")}
              </Button>
            </div>
          </div>
        </Card>
        <br />

        <DialogPopup
          open={this.state.popupInfoOpen}
          onClose={this.handleClosePopupInfo}
        >
          <DialogContent>
            {this.generateInfoTable(this.state.info)}
          </DialogContent>
        </DialogPopup>

        <DialogPopup
          open={this.state.popupHistoryOpen}
          onClose={this.handleClosePopupHistory}
        >
          <DialogContent>
            {this.generateHistoryTable(this.state.history)}
          </DialogContent>
        </DialogPopup>

        <DialogPopup
          open={this.state.popupFoundDomainsOpen}
          onClose={this.handleClosePopupFoundDomains}
        >
          <DialogContent>
            {this.generateFoundDomainsTable(this.state.foundDomains)}
          </DialogContent>
        </DialogPopup>

        <DialogPopup
          open={this.state.openDeletePopup}
          onClose={this.handleCloseDeletePopup}
        >
          <DialogTitle>{t("_confirmation_required")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "_do_you_really_want_to_permanently_delete_all_findings_and_media"
              )}

              <Paper className={classes.alertPaper}>
                <InfoOutlined className={classes.alertIcon}></InfoOutlined>
                {t("_hint_delete_finding_with_cases")}
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeletePopup} color="primary">
              {t("_cancel")}
            </Button>
            <Button onClick={this.deleteConfirmClicked} color="primary">
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          open={this.state.openDeleteFindingPopup}
          onClose={this.handleCloseDeleteFindingPopup}
        >
          <DialogTitle>{t("_confirmation_required")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_do_you_really_want_to)delete_all_findings")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDeleteFindingPopup}
              color="primary"
            >
              {t("_cancel")}
            </Button>
            <Button onClick={this.deleteFindingConfirmClicked} color="primary">
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          open={this.state.popupNotesOpen}
          onClose={this.handleNotesClose}
        >
          <DialogTitle>{t("_notes")}</DialogTitle>
          <DialogContent className={classes.contentContainer}>
            <Table className={classes.table}>
              <TableBody>
                {this.state.notes.map((row) => {
                  return (
                    <TableRow className={classes.row} key={row.id}>
                      <TableCell>
                        {decodeEntities(replaceBrTags(row.note))}
                        <p className={classes.noteTitle}>
                          {row.owner} - {formatDatetime(row.date, "short")}
                        </p>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions className={classes.actionSection}>
            <Input
              className={classes.tbAddNote}
              fullWidth
              multiline
              rowsMax={6}
              placeholder={t("_add_note")}
              value={this.state.newNote}
              id="note"
              onChange={this.handleNewNoteChange}
            />
            <Button
              onClick={this.handleAddNote}
              color="primary"
              className={classes.btnAddNote}
            >
              {t("_add_note")}
            </Button>
          </DialogActions>
        </DialogPopup>
        {this.state.openDeletionResultPopup && (
          <DialogPopup
            open={this.state.openDeletionResultPopup}
            onClose={() => this.onDeletionResultPopupClose()}
          >
            <DialogTitle>{t("_DeletionResult")}</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("_Findings_deleted")}</TableCell>
                      <TableCell>
                        {this.state.deletionResult.deleted_findings}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("_Findings_in_cases")}</TableCell>
                      <TableCell>
                        {this.state.deletionResult.case_findings}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("_search_image_state")}</TableCell>
                      <TableCell>
                        {this.state.deletionResult.image_status === "deleted"
                          ? t("_deleted")
                          : t("_removed_from_search")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.onDeletionResultPopupClose()}
                color="primary"
              >
                {t("_close")}
              </Button>
            </DialogActions>
          </DialogPopup>
        )}
      </div>
    );
  }
}

MediaItem.propTypes = {
  classes: PropTypes.object.isRequired,
  mediaId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    madiaReducer: state.madiaReducer,
    tagsReducer: state.tagsReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionDeleteMedia: deleteMedia,
      actionRemoveDomains: removeDomains,
      actionAddMediaTag: addMediaTag,
      actionRemoveMediaTag: removeMediaTag,
      actionMediaUpdateState: updateMediaStatus,
      actionUpdateAllMediaTags: updateAllMediaTags,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(MediaItem)
  )
);
