import request from "@Utils/api";
import api from "@Configs/apiConfig";

function getDomains(data) {
  // data :
  // pageNum
  // filter
  var filterQuery =
    data.filter === "0" || data.filter === 0 || data.filter == null
      ? ""
      : "&f=" + data.filter;
  return request({
    url:
      api.domainsServer +
      "/domain?ps=20&p=" +
      parseInt(data.pageNum) +
      filterQuery,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function getAllDomainsCount() {
  return request({
    url: api.domainsServer + "/domain?co=true",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function getDomainsSearch(data) {
  // data :
  // pageNum
  // param
  // filter
  var filterQuery =
    data.filter === "0" || data.filter === 0 || data.filter == null
      ? ""
      : "&f=" + data.filter;
  return request({
    url:
      api.domainsServer +
      "/search_domain?ps=20&p=" +
      parseInt(data.pageNum) +
      "&s=" +
      data.param +
      filterQuery,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function getDomain(data) {
  // data :
  // domainId
  return request({
    url: api.domainsServer + "/domain/" + data.domainId,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getAllDomainTags() {
  return request({
    url: api.domainsServer + "/domain/tags",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addDomainsTag(data) {
  // data:
  // tag
  //     name
  //     id
  // domainId

  return request({
    url:
      api.domainsServer +
      "/domain/" +
      encodeURIComponent(data.domainId) +
      "/tags/",
    data: data.tag,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function removeDomainsTag(data) {
  // data:
  // tag
  //     name
  //     id
  // domainId

  return request({
    url:
      api.domainsServer +
      "/domain/" +
      encodeURIComponent(data.domainId) +
      "/tags/" +
      data.tag.id,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addDomainsNote(data) {
  // data:
  // note
  // domainId
  return request({
    url:
      api.domainsServer +
      "/domain/" +
      encodeURIComponent(data.domainId) +
      "/notes/",
    data: data.note,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function updateDomainsStatus(data) {
  // data:
  // domainId
  // status
  // data
  if (data.data == null) data.data = "";
  return request({
    url:
      api.domainsServer +
      "/domain/" +
      encodeURIComponent(data.domainId) +
      "/status",
    method: "PUT",
    data: { status: data.status, type: data.data },
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function createDomainsCase(data) {
  // data:
  // domainId
  // data:

  return request({
    url: api.casesServer + "/case",
    data: data,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function domainsBulkAction(data) {
  // data:
  // tag
  //     name
  //     id
  // type     -> remove_tags, add_tags
  // serach   -> search object || null
  // filterId -> filter id || null

  return request({
    url: api.domainsServer + "/bulk_action/",
    data: data,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}
function deleteDomainNote(data) {
  return request({
    url:
      api.domainsServer +
      "/domain/" +
      encodeURIComponent(data.domainId) +
      "/notes/" +
      data.id,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getAllWhitelistedDomains() {
  return request({
    url: api.domainsServer + "/domain/_whitelist",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addWhitelistDomains(domains) {
  return request({
    url: api.domainsServer + "/domain/_whitelist",
    data: domains,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

/**
 *
 * @param {Array<string>} domains list of domains to delete from whitelisted domains
 * @returns
 */
function deleteWhitelistDomains(domains) {
  return request({
    url: api.domainsServer + "/domain/_whitelist",
    data: domains,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function claimDomains(amount, filterid) {
  var data = {};
  data.amount = amount;
  if (filterid) data.filterID = filterid;
  return request({
    url: api.domainsServer + "/domain/_claim",
    data: data,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function unclaimDomains() {
  return request({
    url: api.domainsServer + "/domain/_claim",
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function unclaimDomainByID(domainID) {
  return request({
    url: api.domainsServer + "/domain/" + encodeURIComponent(domainID) + "/_claim",
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

const Domains = {
  getDomains,
  getDomainsSearch,
  getDomain,
  addDomainsTag,
  removeDomainsTag,
  addDomainsNote,
  deleteDomainNote,
  createDomainsCase,
  updateDomainsStatus,
  getAllDomainTags,
  domainsBulkAction,
  getAllDomainsCount,
  getAllWhitelistedDomains,
  addWhitelistDomains,
  deleteWhitelistDomains,
  claimDomains,
  unclaimDomains,
  unclaimDomainByID,
};

export default Domains;
