const styles = (theme) => ({
  root: {
    // backgroundColor:grey[100],
    marginTop: 16,
  },
  editWhitelistButton: {
    float: "right",
  },
  updateProgress: {
    margin: "5px 0",
  },
  rightColumn: {
    textAlign: "right",
    width: "100%",
  },
  rightColumn2nd: {
    textAlign: "right",
    width: "100%",
    backgroundColor: "#fafafa",
  },
  leftColumn: {
    overflow: "hidden",
    maxWidth: "463px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: "463px",
  },
  leftColumn2nd: {
    overflow: "hidden",
    maxWidth: "463px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: "463px",
    backgroundColor: "#fafafa",
  },
  textFieldThin: {
    margin: "0px 0",
    padding: "0px 0",
    "& .MuiInputBase": {
      margin: "0px 0",
      padding: "0px 0",
    },
  },
  itemsContainer: {
    maxHeight: 313,
    overflowX: "auto",
    scrollbarWidth: "auto !important",
    scrollbarColor: "inherit",
    scrollbarGutter: "inherit",
    "&::-webkit-scrollbar": {
      width: "5px !important",
      display: "inherit !important",
      backgroundColor: "white !important",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "0px !important",
      display: "inherit !important",
      width: "10px !important",
    },
  },
});

export default styles;
