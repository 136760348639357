import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Grid from "@material-ui/core/Grid";

import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Button from "@material-ui/core/Button";
import CasesService from "@Services/Cases";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { bytesToSize } from "@Utils/helpers.js";
import DocTypes from "@Configs/caseDocTypesConfig";
import LoadingBg from "@Components/LoadingBg";
// import {CDNurl} from '@Configs/globalConfig';
import _ from "lodash";
import { translate } from "react-i18next";
import styles from "./styles";
import Scrollbar from "react-perfect-scrollbar-z";

class CaseDoc extends Component {
  constructor(props) {
    super();

    this.onFilesSelected = this.onFilesSelected.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  state = {
    popupOpen: false,
    isUploading: false,
    theFile: {},
    selectedFileName: "_no_file_selected",
    type: "",
    dropdownOpen: false,
  };

  // componentDidMount(){

  // }

  handleDropdownChange = (event) => {
    this.setState({ type: event.target.value }, function () {});
  };

  handleDropdownOpen = () => {
    this.setState({ dropdownOpen: true });
  };

  handleDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handlePopupOpen = () => {
    this.setState({ popupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({
      popupOpen: false,
      type: "",
      selectedFileName: this.props.t("_no_file_selected"),
      isUploading: false,
      theFile: {},
    });
  };

  onFilesSelected(event) {
    var inputFile = event.target.files[0];
    this.setState({
      theFile: inputFile,
      selectedFileName:
        " [ " + bytesToSize(inputFile.size) + " ] - " + inputFile.name,
    });
  }

  handleUpload() {
    this.setState({ isUploading: true });
    var data = {
      // "uploadedAt": new Date(),
      caseId: this.props.caseId,
      file: "",
      fileSize: bytesToSize(this.state.theFile.size),
      fileName: this.state.theFile.name,
      documentTypeId: this.state.type,
      // "documentType": _.find(this.state.docTypes, {id: this.state.type}).name
    };

    data.file = this.state.theFile;

    CasesService.uploadCasesDoc(data)
      .then((response) => {
        this.props.onDocAdded(data, true);

        this.setState({
          popupOpen: false,
          isUploading: false,
          theFile: {},
          selectedFileName: this.props.t("_no_file_selected"),
          type: "",
          dropdownOpen: false,
        });
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
  }

  matchingDocTypes(docType) {
    if (
      _.filter(DocTypes, function (o) {
        return o.value === docType;
      }).length > 0
    )
      return _.filter(DocTypes, function (o) {
        return o.value === docType;
      })[0].name;
    else {
      console.warn("Could find doctype: " + docType);
      return null;
    }
  }

  docDownloadHandler(href) {
    window.open(href, "_blank");
  }

  render() {
    const { classes, data, t } = this.props;

    if (this.props.data == null) {
      return (
        <div>
          <LoadingBg
            pageType="general"
            size="small"
            animated={false}
            message={t("_failed_to_load_resources") + "!"}
          />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.tblContainerDocs}>
          <Scrollbar maxHeight="280px">
            <Table className={classes.tblDocs}>
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <TableRow
                      className={classes.rowDocs}
                      key={index + "_" +Math.random().toString()}
                      onClick={() =>
                        this.docDownloadHandler(
                          row.downloadLink +
                            "?t=" +
                            localStorage.getItem("Token")
                        )
                      }
                    >
                      <TableCell className={classes.rowDocFrist}>
                        <CloudDownloadIcon
                          classes={{ root: classes.downloadIcon }}
                        />
                      </TableCell>
                      <TableCell className={classes.rowDocSecond}>
                        <p title={row.fileName}>{row.fileName}</p>
                        {this.matchingDocTypes(row.documentType)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Scrollbar>
        </div>

        <Button
          onClick={this.handlePopupOpen}
          color="primary"
          className={classes.uploadDocButton}
        >
          {t("_add_new_file")}
        </Button>

        <DialogPopup
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.popupOpen}
          onClose={this.handlePopupClose}
        >
          <DialogTitle>{t("_document_upload")}</DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            <DialogContentText>{t("_please_select_a_file")}</DialogContentText>

            <div className={classes.docTypeRoot}>
              <Grid container>
                <Grid item xs={8}>
                  <p className={classes.fileName}>
                    {this.state.selectedFileName === "_no_file_selected"
                      ? t(this.state.selectedFileName)
                      : this.state.selectedFileName}
                  </p>
                </Grid>
                <Grid item xs={4} className={classes.fileSelect}>
                  <input
                    className={classes.uploadInput}
                    id="icon-button-file"
                    type="file"
                    onChange={this.onFilesSelected}
                    disabled={this.state.isUploading ? true : false}
                  />
                  <label
                    htmlFor="icon-button-file"
                    className={classes.docUpload}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      mini
                      color="secondary"
                      fullWidth
                      aria-label="Add"
                      className={classes.button}
                      component="span"
                      disabled={this.state.isUploading ? true : false}
                    >
                      {t("_select_document")}
                    </Button>
                  </label>
                </Grid>
              </Grid>

              <br />

              <div className={classes.typeSelect}>
                <form autoComplete="off">
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="type-select">
                      {t("_document_type")}
                    </InputLabel>
                    <Select
                      disabled={this.state.isUploading ? true : false}
                      className={classes.selectInput}
                      open={this.state.dropdownOpen}
                      onClose={this.handleDropdownClose}
                      onOpen={this.handleDropdownOpen}
                      value={this.state.type}
                      onChange={this.handleDropdownChange}
                      inputProps={{
                        name: "type",
                        id: "type-select",
                      }}
                    >
                      {DocTypes.map((row, index) => {
                        return (
                          <MenuItem key={index + "_" +Math.random().toString()} value={row.id}>
                            {row.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </form>
              </div>
            </div>
            <br />
            <LinearProgress
              className={classes.uploadProgress}
              color="secondary"
              style={{
                visibility: this.state.isUploading ? "visible" : "hidden",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handlePopupClose}
              color="secondary"
              disabled={this.state.isUploading ? true : false}
            >
              {t("_cancel")}
            </Button>
            <Button
              onClick={this.handleUpload}
              color="primary"
              disabled={
                this.state.isUploading ||
                this.state.type === "" ||
                this.state.selectedFileName === "_no_file_selected"
                  ? true
                  : false
              }
            >
              {t("_upload")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

CaseDoc.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(CaseDoc));
