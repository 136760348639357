import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
// import _ from 'lodash';
// import Button from '@material-ui/core/Button';

import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Close";
import {
  updateFilterRuleSubfilter,
  updateFilterRuleOperation,
  updateFilterRuleValue,
  updateSaved,
} from "@Actions/filterActions";
import Operations from "@Configs/filterOperationsConfig";
import { isFilterCircular } from "@Utils/helpers";
import AutoSuggest from "@Components/AutoSuggest";
import styles from "./styles";
import MediaStatus from "@Configs/mediaStateFilterConfig";
import operatorCountryEnum from "@Configs/operatorCountryConfig";
import DomainWebsiteFilterEnum from "@Configs/domainWebsiteFilterConfig";

const subfilterSpecialConfig = {
  domain: {
    domainTags: "103",
    mediaTags: "108",
    website: "109",
    mediaTagsOnNewFindigns: "110",
    operatorCountry: "114",
  },
  case: {
    domainTags: "206",
    caseTags: "207",
    mediaTags: "208",

    caseCollectionPartner: "205",
    caseState: "204",
    caseType: "210",
  },
  media: {
    mediaTags: "301",
    mediaStatus: "304",
    uplodedBy: "303",
  },
};

class FilterRule extends Component {
  constructor(props) {
    super();
  }

  state = {
    isCreating: false,

    subfilterDropdownOpen: false,
    operationTypeDropdownOpen: false,
    ruleValueFilterDropdownOpen: false,
    ruleValueEnumDropdownOpen: false,
    ruleValueTagDropdownOpen: false,
  };

  // filter type handlerss
  handleSubfilterChange = (event) => {
    var data = {
      subfilterId: event.target.value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };
    this.props.actionUpdateFilterRuleSubfilter(data);

    delete data.subfilterId;
    data.operationId = "0";
    this.props.actionUpdateFilterRuleOperation(data);

    delete data.operationId;
    data.ruleValue = "";
    this.props.actionUpdateFilterRuleValue(data);

    this.props.actionUpdateSaved(false);

    if (event.target.value === "111" || event.target.value === "112") {
      this.handleRuleValueChange({ target: { value: "" } });
      this.handleOperationChange({ target: { value: "70" } });
    }

    if (event.target.value === "113") {
      this.handleOperationChange({ target: { value: "71" } });
    }
  };

  handleSubfilterDropdownOpen = () => {
    this.setState({ subfilterDropdownOpen: true });
  };

  handleSubfilterDropdownClose = () => {
    this.setState({ subfilterDropdownOpen: false });
  };
  // filter type handlerss

  // operation type handlers
  handleOperationChange = (event) => {
    var data = {
      operationId: event.target.value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };
    this.props.actionUpdateFilterRuleOperation(data);
    this.props.actionUpdateSaved(false);
  };

  handleOperationTypeDropdownOpen = () => {
    this.setState({ operationTypeDropdownOpen: true });
  };

  handleOperationTypeDropdownClose = () => {
    this.setState({ operationTypeDropdownOpen: false });
  };
  // operation type handlers

  handleRuleValueChange = (event) => {
    var data = {
      ruleValue: event.target.value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };
    this.props.actionUpdateFilterRuleValue(data);
    this.props.actionUpdateSaved(false);
  };

  handleRuleValueLeftChanged = (event) => {
    var input = event.target.value;
    if (input)
      if (
        input.toLowerCase().length === 0 ||
        /^[a-z]$/.test(input.toLowerCase()) === false
      )
        return;
    const { filterReducer, data } = this.props;

    var previusVal =
      filterReducer.filters[filterReducer.currentFilterId].rules[data.id]
        .ruleValue;
    var value = "";

    if (previusVal) {
      let sp = previusVal.split("-");
      value = input + "-" + sp[1];
    } else {
      value = input + "-";
    }
    var inputData = {
      ruleValue: value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };
    this.props.actionUpdateFilterRuleValue(inputData);
    this.props.actionUpdateSaved(false);
  };

  handleRuleValueRightChanged = (event) => {
    var input = event.target.value;
    if (input)
      if (
        input.toLowerCase().length === 0 ||
        /^[a-z]$/.test(input.toLowerCase()) === false
      )
        return;
    const { filterReducer, data } = this.props;

    var previusVal =
      filterReducer.filters[filterReducer.currentFilterId].rules[data.id]
        .ruleValue;
    var value = "";

    if (previusVal) {
      let sp = previusVal.split("-");
      value = sp[0] + "-" + input;
    } else {
      value = "-" + input;
    }
    var inputData = {
      ruleValue: value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };
    this.props.actionUpdateFilterRuleValue(inputData);
    this.props.actionUpdateSaved(false);
  };

  getSplittedRuleValue = (index) => {
    const { filterReducer, data } = this.props;

    var value =
      filterReducer.filters[filterReducer.currentFilterId].rules[data.id]
        .ruleValue;
    if (!value || value.indexOf("-") === -1) return "";
    var splitted = value.split("-");
    return splitted[index];
  };

  handleRuleValueFilterDropdownOpen = () => {
    this.setState({ ruleValueFilterDropdownOpen: true });
  };
  handleRuleValueFilterDropdownClose = () => {
    this.setState({ ruleValueFilterDropdownOpen: false });
  };

  handleRuleValueTagDropdownOpen = () => {
    this.setState({ ruleValueTagDropdownOpen: true });
  };
  handleRuleValueTagDropdownClose = () => {
    this.setState({ ruleValueTagDropdownOpen: false });
  };

  handleRuleValueEnumDropdownOpen = () => {
    this.setState({ ruleValueEnumDropdownOpen: true });
  };
  handleRuleValueEnumDropdownClose = () => {
    this.setState({ ruleValueEnumDropdownOpen: false });
  };

  handleRuleValueFilterChange = (event) => {
    var data = {
      ruleValue: event.target.value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };
    this.props.actionUpdateFilterRuleValue(data);
    this.props.actionUpdateSaved(false);
  };

  handleRuleValueTagChange = (event) => {
    var data = {
      ruleValue: event.target.value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };
    this.props.actionUpdateFilterRuleValue(data);
    this.props.actionUpdateSaved(false);
  };

  handleRuleValueEnumChange = (event) => {
    var data = {
      ruleValue: event.target.value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };
    this.props.actionUpdateFilterRuleValue(data);
    this.props.actionUpdateSaved(false);
  };

  handleRemoveClicked = () => {
    this.props.handleRemoveRule(this.props.data.id);
    this.props.actionUpdateSaved(false);
  };

  componentDidMount() {
    // get filter rule data
    const { filterReducer, data } = this.props;
    var currentSubfilterId =
      filterReducer.filters[filterReducer.currentFilterId].rules[data.id]
        .subfilterId;
    var currentSubfilterKind =
      currentSubfilterId === "0"
        ? null
        : filterReducer.subfilters[currentSubfilterId].kind;

    // check if we have tag -> "no tag" to fake value
    if (currentSubfilterKind === "tag" && parseInt(data.operationId) === 66) {
      this.handleRuleValueChange({ target: { value: "" } });
      this.props.actionUpdateSaved(true);
    }

    // check if we have case with high/low payments to fake operator and value
    if (
      parseInt(currentSubfilterId) === 111 ||
      parseInt(currentSubfilterId) === 112
    ) {
      this.handleRuleValueChange({ target: { value: "" } });
      this.handleOperationChange({ target: { value: "70" } });
      this.props.actionUpdateSaved(true);
    }

    if (parseInt(currentSubfilterId) === 113) {
      this.handleOperationChange({ target: { value: "71" } });
      this.props.actionUpdateSaved(true);
    }
  }

  valueChanged = (value) => {
    var data = {
      ruleValue: value,
      filterId: this.props.filterReducer.currentFilterId,
      filterRuleId: this.props.data.id,
    };

    this.props.actionUpdateFilterRuleValue(data);
    this.props.actionUpdateSaved(false);
  };

  getAllTags = () => {
    const { filterReducer, data } = this.props;
    var allTags = [];
    if (filterReducer.currentFilterType === "domain") {
      console.log(data.subfilterId.toString());

      if (
        data.subfilterId.toString() ===
          subfilterSpecialConfig.domain.mediaTags ||
        data.subfilterId.toString() ===
          subfilterSpecialConfig.domain.mediaTagsOnNewFindigns
      ) {
        allTags = this.props.tagsReducer.mediaTags;
      } else if (
        data.subfilterId.toString() === subfilterSpecialConfig.domain.domainTags
      ) {
        allTags = this.props.tagsReducer.domainTags;
      } else {
        allTags = [];
      }
    } else if (filterReducer.currentFilterType === "case") {
      if (
        data.subfilterId.toString() === subfilterSpecialConfig.case.domainTags
      ) {
        allTags = this.props.tagsReducer.domainTags;
      } else if (
        data.subfilterId.toString() === subfilterSpecialConfig.case.mediaTags
      ) {
        allTags = this.props.tagsReducer.mediaTags;
      } else {
        allTags = this.props.tagsReducer.caseTags;
      }
    } else {
      // media
      if (
        data.subfilterId.toString() === subfilterSpecialConfig.media.mediaTags
      ) {
      } else {
        allTags = this.props.tagsReducer.mediaTags;
      }
    }

    return allTags;
  };

  getAllEnums = () => {
    const { filterReducer, data } = this.props;
    var allEnums = [];
    if (filterReducer.currentFilterType === "domain") {
      if (data.subfilterId.toString() === subfilterSpecialConfig.domain.website)
        allEnums = DomainWebsiteFilterEnum;

      if (
        data.subfilterId.toString() ===
        subfilterSpecialConfig.domain.operatorCountry
      )
        allEnums = operatorCountryEnum;
    } else if (filterReducer.currentFilterType === "case") {
      if (
        data.subfilterId.toString() ===
        subfilterSpecialConfig.case.caseCollectionPartner
      ) {
        // caseCollectionPartner
        allEnums = this.props.globalReducer.collectionPartners;
      } else if (
        data.subfilterId.toString() === subfilterSpecialConfig.case.caseState
      ) {
        // caseState
        allEnums = this.props.globalReducer.caseStates;
      } else if (
        data.subfilterId.toString() === subfilterSpecialConfig.case.caseType
      ) {
        // caseType
        allEnums = this.props.globalReducer.caseTypes;
      } else {
      }
    } else {
      // media
      if (
        data.subfilterId.toString() === subfilterSpecialConfig.media.mediaStatus
      ) {
        // mediaStatus
        allEnums = MediaStatus;
      } else if (
        data.subfilterId.toString() === subfilterSpecialConfig.media.uplodedBy
      ) {
        // uplodedBy
        allEnums = this.props.globalReducer.users;
      } else {
        allEnums = [];
      }
    }

    return allEnums;
  };

  render() {
    const { classes, filterReducer, data, t } = this.props;

    var currentSubfilterId =
      filterReducer.filters[filterReducer.currentFilterId].rules[data.id]
        .subfilterId;
    var currentSubfilterKind =
      currentSubfilterId === "0"
        ? null
        : filterReducer.subfilters[currentSubfilterId].kind;

    var currentSubfilterOperation =
      filterReducer.filters[filterReducer.currentFilterId].rules[data.id]
        .operationId;

    var numFilters = 0;
    Object.keys(filterReducer.filters).map((key) => {
      var row = filterReducer.filters[key];
      if (row.type === filterReducer.currentFilterType) numFilters++;
      return null;
    });

    var allTags = this.getAllTags();
    var allEnumDropDown = this.getAllEnums();

    return (
      <div className={classes.root}>
        <div className={classes.subfilterSelect}>
          <form autoComplete="off">
            <FormControl
              className={
                parseInt(currentSubfilterId) === 111 ||
                parseInt(currentSubfilterId) === 112
                  ? classes.subfilterFormControlFullLine
                  : classes.subfilterFormControl
              }
            >
              {/* <InputLabel htmlFor="filter-type-select">Select Entity:</InputLabel> */}
              <Select
                disabled={this.state.isCreating ? true : false}
                className={
                  parseInt(currentSubfilterId) === 111 ||
                  parseInt(currentSubfilterId) === 112
                    ? classes.subfilterSelectInputFullLine
                    : classes.subfilterSelectInput
                }
                open={this.state.subfilterDropdownOpen}
                onClose={this.handleSubfilterDropdownClose}
                onOpen={this.handleSubfilterDropdownOpen}
                value={data.subfilterId}
                onChange={this.handleSubfilterChange}
                inputProps={{
                  name: "subfilter",
                  id: "subfilter-select",
                }}
              >
                {Object.keys(filterReducer.subfilters).map((key, index) => {
                  var row = filterReducer.subfilters[key];
                  if (row.type === filterReducer.currentFilterType) {
                    return (
                      <MenuItem
                        key={index + "_" + Math.random().toString()}
                        value={row.id}
                      >
                        {t(row.name)}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
              </Select>
            </FormControl>
          </form>
        </div>

        <div className={classes.operationTypeSelect}>
          <form autoComplete="off">
            <FormControl className={classes.operationTypeFormControl}>
              {/* <InputLabel htmlFor="operation-type-select">Select Entity:</InputLabel> */}
              <Select
                className={
                  parseInt(currentSubfilterId) === 111 ||
                  parseInt(currentSubfilterId) === 112
                    ? classes.none
                    : classes.operationTypeSelectInput
                }
                open={this.state.operationTypeDropdownOpen}
                onClose={this.handleOperationTypeDropdownClose}
                onOpen={this.handleOperationTypeDropdownOpen}
                value={data.operationId}
                onChange={this.handleOperationChange}
                disabled={
                  currentSubfilterId === "0" || this.state.isCreating
                    ? true
                    : false
                }
                inputProps={{
                  name: "operationType",
                  id: "operationType-select",
                }}
              >
                {Operations.map((row, index) => {
                  if (currentSubfilterKind === null) return null;

                  if (row.kind === currentSubfilterKind)
                    return (
                      <MenuItem
                        key={index + "_" + Math.random().toString()}
                        value={row.id}
                      >
                        {t("_" + row.display)}
                      </MenuItem>
                    );
                  else return null;
                })}
              </Select>
            </FormControl>
          </form>
        </div>

        <div className={classes.ruleValueTextFieldContainer}>
          <FormControl
            className={
              parseInt(currentSubfilterId) === 111 ||
              parseInt(currentSubfilterId) === 112
                ? classes.none
                : currentSubfilterKind === "special_2"
                ? classes.ruleValueTextMultiFieldContainer
                : classes.ruleValueFormControl
            }
          >
            {currentSubfilterKind === null && (
              <TextField
                value={data.ruleValue}
                disabled={true}
                id="ruleValue"
                className={classes.ruleValueTextField}
                InputLabelProps={{
                  shrink: true,
                }}
                // margin="dense"
                onChange={this.handleRuleValueChange}
              />
            )}
            {currentSubfilterKind === "text" && (
              <TextField
                value={data.ruleValue}
                id="ruleValue"
                className={classes.ruleValueTextField}
                InputLabelProps={{
                  shrink: true,
                }}
                // margin="dense"
                onChange={this.handleRuleValueChange}
              />
            )}
            {currentSubfilterKind === "number" && (
              <TextField
                value={data.ruleValue}
                type="number"
                id="ruleValue"
                className={classes.ruleValueTextField}
                InputLabelProps={{
                  shrink: true,
                }}
                // margin="dense"
                onChange={this.handleRuleValueChange}
              />
            )}
            {currentSubfilterKind === "date" && (
              <>
                {currentSubfilterOperation === "44" ||
                currentSubfilterOperation === 44 ? (
                  <>
                    <TextField
                      value={data.ruleValue}
                      type="number"
                      id="ruleValue"
                      className={classes.ruleValueTextFieldDays}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // margin="dense"
                      onChange={this.handleRuleValueChange}
                    />
                    <div style={{ marginTop: "6px" }}>
                      <div>&nbsp;{t("_days")}</div>
                    </div>
                  </>
                ) : (
                  <TextField
                    value={data.ruleValue}
                    type="date"
                    id="ruleValue"
                    className={classes.ruleValueTextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // margin="dense"
                    onChange={this.handleRuleValueChange}
                  />
                )}
              </>
            )}
            {currentSubfilterKind === "filter" && numFilters > 1 && (
              <Select
                className={classes.ruleValueFilterSelectInput}
                open={this.state.ruleValueFilterDropdownOpen}
                onClose={this.handleRuleValueFilterDropdownClose}
                onOpen={this.handleRuleValueFilterDropdownOpen}
                value={data.ruleValue}
                onChange={this.handleRuleValueFilterChange}
                disabled={
                  currentSubfilterId === "0" || this.state.isCreating
                    ? true
                    : false
                }
                inputProps={{
                  name: "ruleValueFilter",
                  id: "ruleValueFilter-select",
                }}
              >
                {Object.keys(filterReducer.filters).map((key, index) => {
                  var row = filterReducer.filters[key];
                  if (
                    row.type === filterReducer.currentFilterType &&
                    row.id !== filterReducer.currentFilterId
                  )
                    return (
                      <MenuItem
                        key={index + "_" + Math.random().toString()}
                        value={row.id}
                        disabled={isFilterCircular(
                          row.filters,
                          filterReducer.currentFilterId
                        )}
                      >
                        {row.name}
                      </MenuItem>
                    );
                  else return null;
                })}
              </Select>
            )}
            {currentSubfilterKind === "enum" && (
              <Select
                className={classes.ruleValueEnumInput}
                open={this.state.ruleValueEnumDropdownOpen}
                onClose={this.handleRuleValueEnumDropdownClose}
                onOpen={this.handleRuleValueEnumDropdownOpen}
                value={data.ruleValue}
                onChange={this.handleRuleValueEnumChange}
                inputProps={{
                  name: "ruleValueEnum",
                  id: "ruleValueEnum-select",
                }}
              >
                {Object.keys(allEnumDropDown).map((key, index) => {
                  var row = allEnumDropDown[key];
                  if (row.display === undefined || row.display)
                    if (
                      data.subfilterId.toString() ===
                        subfilterSpecialConfig.case.caseState ||
                      data.subfilterId.toString() ===
                        subfilterSpecialConfig.case.caseType
                    ) {
                      return (
                        <MenuItem
                          key={index + "_" + Math.random().toString()}
                          value={row.value}
                        >
                          {t("_" + row.display)}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem key={index} value={row.id}>
                          {data.subfilterId.toString() ===
                            subfilterSpecialConfig.case.caseCollectionPartner ||
                          data.subfilterId.toString() ===
                            subfilterSpecialConfig.domain.operatorCountry
                            ? row.name
                            : t("_" + row.name)}
                        </MenuItem>
                      );
                    }

                  return null;
                })}
              </Select>
            )}
            {currentSubfilterKind === "tag" &&
              this.props.data.subfilterId.toString() === "103" &&
              !(parseInt(data.operationId) === 66) && (
                <AutoSuggest
                  value={data.ruleValue} // to find the tag with this vlaue
                  suggestions={allTags}
                  onValueChanged={this.valueChanged}
                />
              )}
            {currentSubfilterKind === "tag" &&
              this.props.data.subfilterId.toString() === "206" &&
              !(parseInt(data.operationId) === 66) && (
                <AutoSuggest
                  value={data.ruleValue} // to find the tag with this vlaue
                  suggestions={allTags}
                  onValueChanged={this.valueChanged}
                />
              )}
            {currentSubfilterKind === "tag" &&
              this.props.data.subfilterId.toString() === "108" &&
              !(parseInt(data.operationId) === 66) && (
                <AutoSuggest
                  value={data.ruleValue} // to find the tag with this vlaue
                  suggestions={allTags}
                  onValueChanged={this.valueChanged}
                />
              )}
            {currentSubfilterKind === "tag" &&
              this.props.data.subfilterId.toString() === "110" &&
              !(parseInt(data.operationId) === 66) && (
                <AutoSuggest
                  value={data.ruleValue} // to find the tag with this vlaue
                  suggestions={allTags}
                  onValueChanged={this.valueChanged}
                />
              )}
            {currentSubfilterKind === "tag" &&
              this.props.data.subfilterId.toString() === "301" &&
              !(parseInt(data.operationId) === 66) && (
                <AutoSuggest
                  value={data.ruleValue} // to find the tag with this vlaue
                  suggestions={allTags}
                  onValueChanged={this.valueChanged}
                />
              )}
            {currentSubfilterKind === "tag" &&
              this.props.data.subfilterId.toString() === "208" &&
              !(parseInt(data.operationId) === 66) && (
                <AutoSuggest
                  value={data.ruleValue} // to find the tag with this vlaue
                  suggestions={allTags}
                  onValueChanged={this.valueChanged}
                />
              )}
            {currentSubfilterKind === "tag" &&
              this.props.data.subfilterId.toString() === "207" &&
              !(parseInt(data.operationId) === 66) && (
                <AutoSuggest
                  value={data.ruleValue} // to find the tag with this vlaue
                  suggestions={allTags}
                  onValueChanged={this.valueChanged}
                />
              )}
            {currentSubfilterKind === "tag" &&
              parseInt(data.operationId) === 66 && (
                <TextField
                  value={""}
                  disabled={true}
                  type="text"
                  id="ruleValue"
                  className={classes.ruleValueTextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // margin="dense"
                  onChange={this.handleRuleValueChange}
                />
              )}
            {currentSubfilterKind === "special_2" && (
              <>
                <TextField
                  value={this.getSplittedRuleValue(0)}
                  type="text"
                  id="ruleValue1"
                  className={classes.ruleValueTextFieldLeft}
                  onChange={this.handleRuleValueLeftChanged}
                />{" "}
                <div className={classes.ruleValueSeperator}>{t("_to")}</div>
                <TextField
                  value={this.getSplittedRuleValue(1)}
                  type="text"
                  id="ruleValue2"
                  className={classes.ruleValueTextFieldRight}
                  onChange={this.handleRuleValueRightChanged}
                />
              </>
            )}
          </FormControl>
        </div>
        <FormControl>
          <IconButton
            aria-label="Remove"
            className={
              parseInt(currentSubfilterId) === 111 ||
              parseInt(currentSubfilterId) === 112
                ? classes.removeIconBtnLeft
                : classes.removeIconBtn
            }
            onClick={this.handleRemoveClicked}
          >
            <RemoveIcon className={classes.removeIcon} />
          </IconButton>
        </FormControl>
      </div>
    );
  }
}

FilterRule.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    filterReducer: state.filterReducer,
    tagsReducer: state.tagsReducer,
    globalReducer: state.globalReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionUpdateFilterRuleSubfilter: updateFilterRuleSubfilter,
      actionUpdateFilterRuleOperation: updateFilterRuleOperation,
      actionUpdateFilterRuleValue: updateFilterRuleValue,
      actionUpdateSaved: updateSaved,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(FilterRule)));
