import React, { Component } from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import styles from "./styles";
import CasesService from "@Services/Cases";
import { showSnackbar } from "@Actions/snackbarActions";
import { getCaseMessages } from "@Actions/caseMessagesActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoadingBg from "@Components/LoadingBg";
import PreviousMessageRow from "@Components/cards/CaseCard/CaseMessages/components/PreviousMessageRow";
import SendMessage from "@Components/cards/CaseCard/CaseMessages/components/SendMessage";
import { withStyles } from "@material-ui/core/styles";
import ReplyMessage from "@Components/cards/CaseCard/CaseMessages/components/ReplyMessage";
import CaseMessage from "@Components/cards/CaseCard/CaseMessages/components/CaseMessage";
import { updateCaseTodos } from "@Actions/caseTodosActions";

class CaseMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replyMessage: false,
      replyMessageId: "",
      senderParty: "",
    };
  }

  handleOpenReplyMessage = (id, senderParty) => {
    this.setState({
      replyMessage: true,
      replyMessageId: id,
      senderParty,
    });
  };

  handleCloseReplyMessage = () => {
    this.setState({
      replyMessage: false,
      replyMessageId: null,
      senderParty: null,
    });
  };

  handleSendMessage = (
    receiver,
    message,
    caseId,
    messageId = null,
    callback
  ) => {
    var newData = {
      receiver,
      message,
      messageId,
      caseId,
    };
    CasesService.addCaseMessage(newData)
      .then((response) => {
        CasesService.getCaseMessages({ caseId: caseId })
          .then((response) => {
            this.props.actionGetCaseMessages({
              caseId: caseId,
              messages: response,
            });
            this.props.actionShowSnackbar({
              message: this.props.t("_message_was_sent_successfully"),
              variant: "success",
            });

            callback && callback();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message: this.props.t(
                "_something_went_wrong_please_try_again_later"
              ),
              variant: "error",
            });
            console.log(error);
          });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message:
            error.message ||
            this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  render() {
    const { classes, data, t } = this.props;

    let messages =
      data &&
      data.map((message, index) => {
        const previousMessage = data.filter((el) => el.id === message.replyID);
        const previousMessageComponent = message.replyID && (
          <PreviousMessageRow
            t={t}
            key={index + "_" +Math.random().toString()}
            message={previousMessage[0].message}
            sender={previousMessage[0].sender}
            receiverParty={previousMessage[0].receiverParty}
          />
        );

        return (
          <CaseMessage
            handleReplyMessage={this.handleOpenReplyMessage}
            data={data}
            message={message}
            index={index}
            replyMessage={
              this.state.replyMessage &&
              message.id === this.state.replyMessageId
            }
            previousMessage={previousMessageComponent}
          />
        );
      });

    if (data == null) {
      return (
        <div>
          <LoadingBg
            pageType="general"
            size="small"
            animated={false}
            message={t("_faild_to_load_resources") + "!"}
          />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <table className={classes.tblTitle}>
          <tbody>
            <tr>
              <td className={classes.trLeft}>
                <b>{t("_messages")}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={classes.messageContainer}>{messages}</div>
        {!this.state.replyMessage && (
          <SendMessage
            handleSendMessage={this.handleSendMessage}
            caseId={this.props.caseId}
          />
        )}
        {this.state.replyMessage && (
          <ReplyMessage
            handleCloseReply={this.handleCloseReplyMessage}
            handleSendMessage={this.handleSendMessage}
            replyMessageId={this.state.replyMessageId}
            caseId={this.props.caseId}
            senderParty={this.state.senderParty}
          />
        )}
      </div>
    );
  }
}

CaseMessages.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    caseMessagesReducer: state.caseMessagesReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionGetCaseMessages: getCaseMessages,
      actionShowSnackbar: showSnackbar,
      actionUpdateCaseTodos: updateCaseTodos,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(CaseMessages)
  )
);
