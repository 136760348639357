export const CASE_CREATION_BUTTON = "case_creation_button";
export const CASE_START_BUTTON = "case_start_button";
export const CASE_CONFIRM_BUTTON = "case_confirm_button";
export const LEGAL_BUTTON = "legal_button";
export const POST_LICENCING_BUTTON = "post_licencing_button";
export const TERMINATE_CASE_BUTTON = "terminate_case_button";
export const ACTIVATE_CASE_BUTTON = "activate_case_button";
export const REVERT_DOMAIN_BUTTON = "revert_doamin_button";
export const WHITELIST_BUTTON = "whitelist_button";
export const PUSH_TO_DOCUMENTATION = "push_to_documentation_button";
export const ACCOUNTING_POST_LICENCING = "accounting_post_licencing_button";
export const LEGAL_ACTION_BUTTON = "legal_action_button";
export const UNCLAIM_BUTTON = "unclaim_button";
