import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import { translate } from "react-i18next";
import styles from "./styles";

class UnclaimButton extends Component {
  constructor(props) {
    super();

    this.actionClicked = this.actionClicked.bind(this);
  }

  state = {
    open: false,
  };

  actionClicked() {
    this.props.onActionClicked(
      this.props.cardData.type,
      this.props.actionType,
      null
    );
    this.setState({ open: false });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={this.actionClicked}
        >
          {t("_unclaim_domain")}
        </Button>
      </div>
    );
  }
}

UnclaimButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(UnclaimButton));
