import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CasePanel from "@Components/panels/CasePanel";

import KeyDown from "@material-ui/icons/KeyboardArrowDown";
import KeyUp from "@material-ui/icons/KeyboardArrowUp";
import { Link } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CaseWarning from "./CaseWarning";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  formatDomainName,
  formatDatetime,
  isNull,
  numberToLocaleString,
} from "@Utils/helpers.js";
import Tooltip from "@material-ui/core/Tooltip";
import {
  addCasesTag,
  removeCasesTag,
  addCasesNote,
  addCasesDoc,
  updateCasesCollectionPartner,
  updateCasesFees,
  updateCasesStatus,
  updateCase,
  getCaseFees,
} from "@Actions/casesActions";

import { updateCaseTodos } from "@Actions/caseTodosActions";
import { getCaseMessages } from "@Actions/caseMessagesActions";

import { updateAllCaseTags } from "@Actions/tagsActions";

import { updateFindingsCaseStatus } from "@Actions/findingsActions";
import TagInput from "@Components/TagInput";

import CaseFee from "./CaseFee";
import CaseDoc from "./CaseDoc";
import CaseDomainInfo from "./CaseDomainInfo";
import CaseMessagesContainer from "./CaseMessages";

import CardImages from "../components/CardImages";
import CardNote from "../components/CardNote";
import CardButtons from "../components/CardButtons";
import CardLabel from "../components/CardLabel";
import ChangeRouteButton from "../components/CardButtons/ChangeRouteButton";

import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Button from "@material-ui/core/Button";
import CasesService from "@Services/Cases";
import _ from "lodash";
import { showSnackbar } from "@Actions/snackbarActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { translate } from "react-i18next";
import styles from "./styles";

class CaseCard extends Component {
  constructor(props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.editLowyerClicked = this.editLowyerClicked.bind(this);
    this.handleUpdateCollectionPartner =
      this.handleUpdateCollectionPartner.bind(this);
  }

  state = {
    tabIndex: 0,

    popupCollectionPartnerOpen: false,
    isUpdatingCollectionPartner: false,

    dropdownOpen: false,

    collectionPartner: "",
    collectionPartnerList: [],

    collectionPartnerHovering: false,
    caseDocuments: null,
    casePayments: null,
    caseDomainInfo: null,

    popupHistoryOpen: false,
    caseHistory: [],

    open: false,
    detailsOpen: false,
  };

  componentDidMount() {
    if (this.props.selectdedIndex) this.handleChange(null, 4);
  }

  handleDropdownChange = (event) => {
    this.setState({ collectionPartner: event.target.value });
  };

  handleDropdownOpen = () => {
    this.setState({ dropdownOpen: true });
  };

  handleDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handlePopupClose = () => {
    this.setState({
      popupCollectionPartnerOpen: false,
      isUpdatingCollectionPartner: false,
    });
  };

  handleHistoryPopupClose = () => {
    this.setState({ popupHistoryOpen: false });
  };

  handleMouseEnter() {
    this.setState({ collectionPartnerHovering: true });
  }

  handleMouseLeave() {
    this.setState({ collectionPartnerHovering: false });
  }

  handleChange = (event, tabIndex) => {
    if (tabIndex === 1) {
      this.tabFeesSelected();
    } else if (tabIndex === 2) {
      this.tabDocumentsSelected();
    } else if (tabIndex === 3) {
      this.tabDomainInfoSelected();
    } else if (tabIndex === 4) {
      this.tabCaseMessagesSelected();
    } else {
      this.tabFeesSelected();
    }

    this.setState({ tabIndex });
  };

  tabFeesSelected = () => {
    CasesService.getCaseFees({ caseId: this.props.caseId })
      .then((response) => {
        response.collectionPartnerFee = response.lawyerFee;
        response.collectionPartnersPartOfSettlementSum =
          response.lawyersPartOfSettlementSum;
        this.props.actionGetCaseFees({
          caseId: this.props.caseId,
          fees: response,
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  tabDocumentsSelected = () => {
    CasesService.getCaseDocuments({ caseId: this.props.caseId })
      .then((response) => {
        this.setState({ caseDocuments: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  tabPaymentsSelected = () => {
    CasesService.getCasepayments({ caseId: this.props.caseId })
      .then((response) => {
        this.setState({ casePayments: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  tabDomainInfoSelected = () => {
    CasesService.getCaseDomain({ caseId: this.props.caseId })
      .then((response) => {
        // console.log(response);
        this.setState({ caseDomainInfo: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  tabCaseMessagesSelected = () => {
    CasesService.getCaseMessages({ caseId: this.props.caseId })
      .then((response) => {
        this.props.actionGetCaseMessages({
          caseId: this.props.caseId,
          messages: response,
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  editLowyerClicked() {
    CasesService.getCollectionPartnerList()
      .then((response) => {
        this.setState({ collectionPartnerList: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });

    var caseReducerData = this.props.casesReducer.results[this.props.caseId];
    var collectionPartnerID = null;
    if (caseReducerData.lawyer) collectionPartnerID = caseReducerData.lawyer.id;
    this.setState({ collectionPartner: collectionPartnerID }, function () {
      this.setState({ popupCollectionPartnerOpen: true });
    });
  }

  historyClicked = () => {
    var data = {
      caseId: this.props.caseId,
    };
    CasesService.getCaseHistory(data)
      .then((response) => {
        this.setState({ caseHistory: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });

    this.setState({ popupHistoryOpen: true });
  };

  tagsChanged = (actionName, tag) => {
    var data = {
      caseId: this.props.caseId,
      tag: tag,
    };

    if (actionName === "create-option") {
      CasesService.addCasesTag(data)
        .then((response) => {
          data.tag.id = response.id;
          this.props.actionAddCasesTag(data);
          this.props.actionUpdateAllCaseTags(data.tag);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    } else if (actionName === "remove-option") {
      CasesService.removeCasesTag(data)
        .then((response) => {
          console.log(response);
          this.props.actionRemoveCasesTag(data);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    } else {
      // select-option
      CasesService.addCasesTag(data)
        .then((response) => {
          console.log(response);
          data.tag.id = response.id;
          this.props.actionAddCasesTag(data);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    }

    return null;
  };

  noteAdded = (note) => {
    var data = {
      caseId: this.props.caseId,
      note: note,
    };

    CasesService.addCasesNote(data)
      .then((response) => {
        this.props.actionAddCasesNote(data);
        this.props.actionShowSnackbar({
          message: this.props.t("_note_added_successfully") + "!",
          variant: "success",
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  handleUpdateCollectionPartner() {
    this.setState({ isUpdatingCollectionPartner: true });
    let collectionPartner = _.find(this.state.collectionPartnerList, {
      id: this.state.collectionPartner,
    });

    if (this.state.collectionPartner === "sales")
      collectionPartner = { id: "sales", name: "sales" };
    var data = {
      caseId: this.props.caseId,
      data: collectionPartner,
    };

    CasesService.updateCasesCollectionPartner(data)
      .then((response) => {
        this.props.actionUpdateCasesCollectionPartner(data);

        this.setState({
          popupCollectionPartnerOpen: false,
          isUpdatingCollectionPartner: false,
          dropdownOpen: false,
        });

        this.props.actionShowSnackbar({
          message:
            this.props.t("_collection_partner_updated_successfully") + "!",
          variant: "success",
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  }

  paymentAdded = (data, status) => {
    // var newData = {
    //   caseId : this.props.caseId,
    //   data: data
    // }

    if (status) {
      this.tabPaymentsSelected();

      CasesService.getCase({ caseId: this.props.caseId })
        .then((response) => {
          this.props.actionUpdateCase({
            results: response,
            caseId: this.props.caseId,
          });
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });

      this.props.actionShowSnackbar({
        message: this.props.t("_payment_added_successfully") + "!",
        variant: "success",
      });
    } else {
      // error
    }
  };

  feesUpdated = (data, status) => {
    // var newData = {
    //   caseId : this.props.caseId,
    //   data: data
    // }

    if (status) {
      this.tabFeesSelected();
      this.props.actionShowSnackbar({
        message: this.props.t("_claim_updated_successfully") + "!",
        variant: "success",
      });
    } else {
      // error
    }
  };

  docAdded = (data, status) => {
    // var newData = {
    //   caseId : this.props.caseId,
    //   data: data
    // }

    if (status) {
      // this.props.actionAddCasesDoc(newData);
      this.tabDocumentsSelected();
      this.props.actionShowSnackbar({
        message: this.props.t("_document_uploaded_successfully") + "!",
        variant: "success",
      });
    } else {
      // error
    }
  };

  actionClicked = (nextStatus, data) => {
    try {
      var caseReducerData = this.props.casesReducer.results[this.props.caseId];

      var newData = {
        caseId: caseReducerData.info.caseId,
        status: nextStatus,
        data: data,
      };

      CasesService.updateCasesStatus(newData)
        .then((response) => {
          this.props.actionUpdateCasesStatus(newData);
          this.props.actionUpdateFindingsCaseStatus(newData);

          CasesService.getCase({ caseId: this.props.caseId })
            .then((response) => {
              this.props.actionUpdateCase({
                results: response,
                caseId: this.props.caseId,
              });
            })
            .catch((error) => {
              // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
              console.log(error);
            });

          this.props.actionShowSnackbar({
            message: this.props.t("_case_updated_successfully"),
            variant: "success",
          });

          CasesService.getCaseTodos()
            .then((response) => {
              this.props.actionUpdateCaseTodos(response);
            })
            .catch((err) => {
              console.log(
                "Error while update Case Todos after draft state change.",
                err
              );
            });
        })
        .catch((error) => {
          console.log(error);
          this.props.actionShowSnackbar({
            message: error.message,
            variant: "error",
          });
        });
    } catch (error) {
      this.props.actionShowSnackbar({
        message:
          error.message ||
          this.props.t("_something_went_wrong_please_try_again_later"),
        variant: "error",
      });
      console.log(error);
    }
  };

  generateHistory = (data) => {
    const { classes, t } = this.props;

    if (data === null) {
      return <div></div>;
    }

    return (
      <div className={classes.tblContainerHistory}>
        <Table className={classes.tblDeatils}>
          <TableBody>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                <b className={classes.historyTitle}>{t("_history")}</b>
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}></TableCell>
              <TableCell className={classes.rowDetailsSecond}></TableCell>
            </TableRow>
            {data.map((item, index) => (
              <TableRow className={classes.rowDeatils} key={index + "_" +Math.random().toString()}>
                <TableCell className={classes.rowDetailsFrist}>
                  <b>
                    <p className={classes.nowrap}>{item.userName} </p>
                  </b>
                </TableCell>
                <TableCell padding="none" className={classes.rowDetailsFrist}>
                  <b></b>
                </TableCell>
                <TableCell padding="none" className={classes.rowDetailsSecond}>
                  {formatDatetime(item.dateCreated, "medium")}
                </TableCell>
              </TableRow>
            ))}
            {data === undefined || data.length === 0 ? (
              <TableRow className={classes.rowDeatils}>
                <TableCell className={classes.rowDetailsFrist}>
                  <b>
                    <p className={classes.nowrap}>
                      {t("_history_data_on_the_way")}
                    </p>
                  </b>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  handleClickOpen = () => {
    this.setState({ open: true });
    this.props.onDetailsOpened(this.props.caseId);

    document
      .getElementById(this.props.caseId)
      .scrollIntoView({ block: "center", behavior: "smooth" });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.onDetailsOpened(null);
  };

  render() {
    const { classes, t } = this.props;
    const { tabIndex } = this.state;

    var caseReducerData = this.props.casesReducer.results[this.props.caseId];

    let caseWarning = caseReducerData.images.some(
      (image) => image.statusChangeReason
    );

    const cardData = {
      type: "case",
      status: caseReducerData.info.status,
      id: this.props.caseId,
      newFindings: 1,
      statusType: caseReducerData.info.type,
      caseInfo: caseReducerData.info,
    };

    if (
      this.props.searchReducer.search.class != null &&
      this.props.page !== "cases" &&
      this.props.page !== "search"
    ) {
      localStorage.setItem("isSearch", "1");
    }

    var collectionPartnerEditable = caseReducerData.info.status === 3;

    const messagesTab =
      caseReducerData.info.numUnreadMessages > 0 ? (
        <div className={classes.messageTab}>
          {t("_messages")}
          <div className={classes.unreadMessages}>
            {caseReducerData.info.numUnreadMessages}
          </div>
        </div>
      ) : (
        <div>{t("_messages")}</div>
      );

    return (
      <div className={classes.root} id={cardData.id}>
        <Card className={classes.card}>
          <AppBar position="static" color="default" className={classes.tabBar}>
            <Tabs
              value={this.state.tabIndex}
              onChange={this.handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
            >
              <Tab
                label={
                  <Tooltip
                    title={caseReducerData.info.domain}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div className={classes.caseTitle}>
                      {formatDomainName(caseReducerData.info.domain)}
                    </div>
                  </Tooltip>
                }
                className={classes.tabTitle}
              />
              <Tab className={classes.tabTitle} label={t("_claim")} />
              <Tab className={classes.tabTitle} label={t("_documents")} />
              <Tab className={classes.tabTitle} label={t("_domain_info")} />
              <Tab className={classes.tabTitle} label={messagesTab} />
            </Tabs>
          </AppBar>
          {/* if there is error show this component */}
          {(this.props.page === "search" ||
            this.props.page === "cases" ||
            this.props.page === "case") &&
            caseReducerData.info.abortionReason && (
              <CaseWarning
                caseWarning={t("_" + caseReducerData.info.abortionReason)}
              />
            )}
          {(this.props.page === "search" ||
            this.props.page === "cases" ||
            this.props.page === "case") &&
            caseWarning && (
              <CaseWarning
                caseWarning={t(
                  "_findings_of_the_case_has_been_removed_added_by_pixray"
                )}
              />
            )}
          <div className={classes.details}>
            <CardContent
              className={classNames(classes.content, classes.imageContainer)}
            >
              <CardImages images={caseReducerData.images} />
            </CardContent>
            <CardContent
              className={classNames(
                classes.content,
                tabIndex === 5
                  ? classes.infoContainerMessage
                  : classes.infoContainer
              )}
            >
              {tabIndex === 0 && (
                <div className={classes.middleSection}>
                  <table className={classes.tblGeneral}>
                    <tbody>
                      <tr>
                        <td className={classes.tdTop}>{t("_license_sum")}</td>

                        {isNull(caseReducerData.info.licenseSum) ? (
                          <td className={classes.tdRight}>0.00 EUR</td>
                        ) : (
                          <td className={classes.tdRight}>
                            {numberToLocaleString(
                              caseReducerData.info.licenseSum
                            )}{" "}
                            EUR
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className={classes.tdTop}>{t("_payments")}</td>

                        {isNull(caseReducerData.info.casePayment) ? (
                          <td className={classes.tdRight}>0.00 EUR</td>
                        ) : (
                          <td className={classes.tdRight}>
                            {numberToLocaleString(
                              caseReducerData.info.casePayment
                            )}{" "}
                            EUR
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className={classes.tdTop}>{t("_case_id")}</td>

                        <td className={classes.tdRight}>
                          {caseReducerData.info.refNumber}
                          {caseReducerData.info.additionalCaseNumber
                            ? " - " + caseReducerData.info.additionalCaseNumber
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        {collectionPartnerEditable && false ? (
                          <>
                            <td
                              className={classes.tdTopPointer}
                              onMouseEnter={this.handleMouseEnter}
                              onMouseLeave={this.handleMouseLeave}
                              onClick={this.editLowyerClicked}
                            >
                              {this.state.collectionPartnerHovering && (
                                <EditIcon className={classes.editIcon} />
                              )}
                              <b>{t("_claim_route")}: </b>{" "}
                            </td>
                            <td>{caseReducerData.info.collectionPartner}</td>
                          </>
                        ) : (
                          <>
                            <td className={classes.tdTop}>
                              <b>{t("_claim_route")}: </b>
                            </td>
                            <td className={classes.tdRight}>
                              {caseReducerData.info.route !== "sales" &&
                              caseReducerData.info.route !== "later"
                                ? caseReducerData.info.collectionPartner
                                : caseReducerData.info.route === "later"
                                ? "--"
                                : "sales"}
                            </td>
                          </>
                        )}
                      </tr>
                      <tr>
                        <td className={classes.tdTop}>
                          <b>{t("_created_on")}:</b>
                        </td>
                        <td>
                          {formatDatetime(
                            caseReducerData.info.createdOn,
                            "medium"
                          )}
                          &nbsp;<b>{t("_by")}</b>&nbsp;
                          {caseReducerData.info.creator}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <p className={classes.cardSectionTitle}>{t("_notes")}</p>

                  <CardNote
                    notes={caseReducerData.notes}
                    onNoteAdded={this.noteAdded}
                  />

                  <p className={classes.cardSectionTitle}>{t("_tags")}</p>
                  <TagInput
                    tags={
                      this.props.tagsReducer && this.props.tagsReducer.caseTags
                    }
                    value={caseReducerData.tags}
                    onTagsChanged={this.tagsChanged}
                    isMulti={true}
                    isCreatable={true}
                  />
                </div>
              )}
              {tabIndex === 1 && (
                <div>
                  <CaseFee
                    caseId={this.props.caseId}
                    data={caseReducerData.fees}
                    status={caseReducerData.info.status}
                    onFeesUpdated={this.feesUpdated}
                    onPaymentAdded={this.paymentAdded}
                    casePayments={this.state.casePayments}
                  />
                </div>
              )}
              {tabIndex === 2 && (
                <div>
                  <CaseDoc
                    caseId={this.props.caseId}
                    data={this.state.caseDocuments !== null?this.state.caseDocuments.sort((a,b)=>new Date(b.date) - new Date(a.date)):null}
                    onDocAdded={this.docAdded}
                  />
                </div>
              )}
              {tabIndex === 3 && (
                <div>
                  <CaseDomainInfo
                    caseId={this.props.caseId}
                    data={this.state.caseDomainInfo}
                    caseState={caseReducerData.info.status}
                    onDomainInformationUpdated={this.tabDomainInfoSelected}
                  />
                </div>
              )}
              {tabIndex === 4 && (
                <CaseMessagesContainer
                  page={this.props.page}
                  caseId={this.props.caseId}
                />
              )}
            </CardContent>
            {tabIndex !== 4 && (
              <CardContent
                className={classNames(classes.content, classes.buttonContainer)}
              >
                <CardLabel cardData={cardData} />
                <CardButtons
                  cardData={cardData}
                  onActionClicked={this.actionClicked}
                />
                {collectionPartnerEditable && (
                  <ChangeRouteButton
                    key={"_special_" + cardData.id}
                    actionType={3}
                    cardData={cardData}
                    onActionClicked={this.editLowyerClicked}
                  ></ChangeRouteButton>
                )}

                {this.props.page === "case" &&
                  _.size(this.props.casesReducer.results) > 1 &&
                  this.props.searchReducer.search.class == null && (
                    <Link to="/cases">
                      <Button color="primary" className={classes.showMore}>
                        {t("_show_less")}
                        <KeyUp className={classes.rightIcon} />
                      </Button>
                    </Link>
                  )}
                {(this.props.page === "cases" ||
                  this.props.page === "search") &&
                  this.props.page !== "case" &&
                  this.props.detailsOpen === false && (
                    <Button
                      onClick={this.handleClickOpen}
                      color="primary"
                      className={classes.showMore}
                    >
                      {t("_show_more")}
                      <KeyDown className={classes.rightIcon} />
                    </Button>
                  )}
                {(this.props.page === "cases" ||
                  this.props.page === "search") &&
                  this.props.page !== "case" &&
                  this.props.detailsOpen === true && (
                    <Button
                      onClick={this.handleClose}
                      color="primary"
                      className={classes.showMore}
                    >
                      {t("_show_less")}
                      <KeyDown className={classes.rightIcon} />
                    </Button>
                  )}
              </CardContent>
            )}
          </div>
        </Card>
        <br />
        {this.props.detailsOpen && (
          <CasePanel
            domainId={caseReducerData.info.domain}
            caseId={this.props.caseId}
            pageType={"case"}
          />
        )}
        <br />
        <DialogPopup
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.popupCollectionPartnerOpen}
          onClose={this.handlePopupClose}
        >
          <DialogTitle>{t("_select_collection_partner")}</DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.collectionPartnerSelectRoot}>
              <div className={classes.collectionPartnerSelect}>
                <form autoComplete="off">
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="collectionPartner-select">
                      {t("_collection_partner")}
                    </InputLabel>
                    <Select
                      disabled={
                        this.state.isUpdatingCollectionPartner ? true : false
                      }
                      className={classes.selectInput}
                      open={this.state.dropdownOpen}
                      onClose={this.handleDropdownClose}
                      onOpen={this.handleDropdownOpen}
                      value={this.state.collectionPartner}
                      onChange={this.handleDropdownChange}
                      inputProps={{
                        name: "collectionPartner",
                        id: "collectionPartner-select",
                      }}
                    >
                      {this.state.collectionPartnerList.map((row, index) => {
                        return (
                          <MenuItem key={index + "_" +Math.random().toString()} value={row.id}>
                            {row.name}
                          </MenuItem>
                        );
                      })}
                      <MenuItem key={-1} value={"sales"}>
                        {t("_sales_team")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </form>
              </div>
            </div>
            <br />
            <LinearProgress
              className={classes.uploadProgress}
              color="secondary"
              style={{
                visibility: this.state.isUpdatingCollectionPartner
                  ? "visible"
                  : "hidden",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handlePopupClose}
              color="secondary"
              disabled={this.state.isUpdatingCollectionPartner ? true : false}
            >
              {t("_cancel")}
            </Button>
            <Button
              onClick={this.handleUpdateCollectionPartner}
              color="primary"
              disabled={this.state.isUpdatingCollectionPartner}
            >
              {t("_update")}
            </Button>
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          open={this.state.popupHistoryOpen}
          onClose={this.handleHistoryPopupClose}
        >
          <DialogContent>
            {this.generateHistory(this.state.caseHistory)}
          </DialogContent>
        </DialogPopup>
      </div>
    );
  }
}

CaseCard.propTypes = {
  classes: PropTypes.object.isRequired,
  caseId: PropTypes.string.isRequired,
  page: PropTypes.oneOf(["case", "cases", "search"]).isRequired,
};

function mapStateToProps(state) {
  return {
    casesReducer: state.casesReducer,
    searchReducer: state.searchReducer,
    tagsReducer: state.tagsReducer,
    caseMessagesReducer: state.caseMessagesReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionAddCasesTag: addCasesTag,
      actionRemoveCasesTag: removeCasesTag,
      actionAddCasesNote: addCasesNote,
      actionAddCasesDoc: addCasesDoc,
      actionGetCaseMessages: getCaseMessages,
      actionUpdateCasesCollectionPartner: updateCasesCollectionPartner,
      actionUpdateCasesFees: updateCasesFees,
      actionUpdateCasesStatus: updateCasesStatus,
      actionUpdateCase: updateCase,
      actionGetCaseFees: getCaseFees,
      actionUpdateAllCaseTags: updateAllCaseTags,
      actionUpdateCaseTodos: updateCaseTodos,
      actionUpdateFindingsCaseStatus: updateFindingsCaseStatus,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(CaseCard)
  )
);
