import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { showSnackbar } from "@Actions/snackbarActions";
import DomainsService from "@Services/Domains";
import styles from "./styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogPopup from "@Components/DialogPopup";

class WhitelistSettings extends Component {
  constructor(props) {
    super();
  }

  state = {
    whitelistPopupOpen: false,
    isUpdating: false,
    items: [],
    addInput: null,
    popupMessage: "",
    showSuccessPopup: false,
    domainFilter: null,
    deleteDialogOpen: false,
  };

  componentDidMount = async () => {
    var retval = await DomainsService.getAllWhitelistedDomains();
    this.setState({ items: retval });
  };

  handleEditWhitelists = () => {
    DomainsService.getAllWhitelistedDomains()
      .then((retval) => {
        var onlyManual = retval.filter((x) => x.reason === "supplied");
        this.setState({ items: onlyManual });
      })
      .catch((e) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_whitelist_load_error"),
          variant: "error",
        });
      });
    this.setState({ whitelistPopupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({
      whitelistPopupOpen: false,
      isUpdating: false,
      addInput: null,
      domainFilter: null,
    });
  };

  getItemTypeName = (type) => {
    let t = this.props.t;
    switch (type) {
      case "supplied":
        return t("_supplied");
      case "tolerated":
        return t("_tolerated");
      case "customer":
        return t("_customer");
      case "junk":
        return t("_junk");
      default:
        return t("_not_set");
    }
  };

  handleDeleteButtonClicked = (domain) => {
    DomainsService.deleteWhitelistDomains([domain])
      .then(async (data) => {
        //reload
        var retval = await DomainsService.getAllWhitelistedDomains();
        this.setState({ items: retval });
        // snackbar success

        this.props.actionShowSnackbar({
          message: this.props.t("_whitelist_delete_success"),
          variant: "success",
        });
      })
      .catch((e) => {
        // snackbar error

        this.props.actionShowSnackbar({
          message: this.props.t("_whitelist_delete_error"),
          variant: "error",
        });
      });
  };

  handleBulkDeleteClicked = () => {
    let domains = this.state.items
      .filter((x) =>
        this.state.domainFilter == null
          ? true
          : x.name.indexOf(this.state.domainFilter) >= 0
      )
      .map((x) => x.name);
    DomainsService.deleteWhitelistDomains(domains)
      .then(async (data) => {
        //reload
        var retval = await DomainsService.getAllWhitelistedDomains();
        this.setState({
          items: retval,
          deleteDialogOpen: false,
          domainFilter: null,
        });
        // snackbar success

        this.props.actionShowSnackbar({
          message: this.props.t("_whitelist_delete_success"),
          variant: "success",
        });
      })
      .catch((e) => {
        // snackbar error

        this.props.actionShowSnackbar({
          message: this.props.t("_whitelist_delete_error"),
          variant: "error",
        });
      });
  };

  handleFilterChanged = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      event.target.value === undefined ||
      event.target.value === null ||
      event.target.value === ""
    ) {
      this.setState({ domainFilter: null });
    } else {
      this.setState({ domainFilter: event.target.value });
    }
  };

  getItems = () => {
    const { classes, t } = this.props;
    var retval = [];

    if (this.state.items.length === 0) {
      return <>{t("_no_items")}</>;
    } else {
      let filtered = this.state.items
        .filter((x) =>
          this.state.domainFilter == null
            ? true
            : x.name.indexOf(this.state.domainFilter) >= 0
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      if (filtered.length === 0) {
        retval = (
          <>
            <div key={"domain_whitelistitem_"}>
              <table style={{ width: "100%" }}>
                <tr>
                  <td
                    style={{ textAlign: "center", width: "100%" }}
                    className={classes.leftColumn2nd}
                  >
                    {t("_filtered_out_evereything")}
                  </td>
                </tr>
              </table>
            </div>
          </>
        );
      } else
        retval = filtered.map((item, index) => {
          return (
            <div
              key={
                "domain_whitelistitem_" +
                item.reason +
                "_" +
                index +
                "_" +
                Math.random().toString()
              }
            >
              <table style={{ width: "100%" }}>
                <tr>
                  <td
                    className={
                      index % 2 === 0
                        ? classes.leftColumn2nd
                        : classes.leftColumn
                    }
                  >
                    {item.name}
                  </td>
                  <td>
                    <Button
                      color="secondary"
                      onClick={() => this.handleDeleteButtonClicked(item.name)}
                    >
                      X
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          );
        });
    }
    return retval;
  };

  handleTextFieldChange = (event) => {
    event.stopPropagation();
    this.setState({ addInput: event.target.value });
  };

  handleAddSuceeded = (response) => {
    this.setState({ isUpdating: false });
    if (response && Array.isArray(response)) {
      var addedDomains = response.filter((x) => x.status === "added");

      this.setState({
        items: [
          ...this.state.items,
          ...addedDomains.map((x) => {
            return { reason: "supplied", name: x.domain };
          }),
        ],
        addInput: this.state.addInput
          .split(/\r?\n/)
          .filter(
            (x) =>
              addedDomains.findIndex((y) => y.domain === x) === -1 ||
              addedDomains.length === 0
          )
          .join("\n"),
      });

      let message =
        addedDomains.length.toString() +
        " " +
        (addedDomains.length === 1
          ? this.props.t("_domain_where_added")
          : this.props.t("_domains_where_added"));

      let haveExistedBefore = this.state.addInput
        .split(/\r?\n/)
        .filter(
          (x) =>
            addedDomains.findIndex((y) => y.domain === x) === -1 ||
            addedDomains.length === 0
        );

      if (
        haveExistedBefore.length > 0 &&
        (haveExistedBefore.length !== 1 || haveExistedBefore[0] !== "")
      ) {
        message +=
          " " +
          haveExistedBefore.length +
          " " +
          (haveExistedBefore.length === 1
            ? this.props.t("_domain_existed_before_and_hint")
            : this.props.t("_domains_existed_before_and_hint"));
      }

      this.setState({ popupMessage: message, showSuccessPopup: true });
    }
  };

  handleAddClicked = (event) => {
    if (!this.state.addInput) return;
    this.setState({ isUpdating: true });
    let convertedAddInput = this.state.addInput.split(/\r?\n/);
    let cleanedInput = convertedAddInput.map((x) => x.trim());
    DomainsService.addWhitelistDomains(cleanedInput)
      .then((response) => {
        this.handleAddSuceeded(response);
      })
      .catch((e) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_whitelist_add_error"),
          variant: "error",
        });
      });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <div>&nbsp;</div>
        <Typography>
          {this.state.items.length} {t("_whitelist_contains")}.
        </Typography>
        <Button
          onClick={this.handleEditWhitelists}
          color="primary"
          className={classes.editWhitelistButton}
        >
          {t("_edit_whitelist_domains")}
        </Button>

        <DialogPopup
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.whitelistPopupOpen}
          onClose={this.handlePopupClose}
        >
          <DialogTitle>{t("_edit_whitelist_domains")}</DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            <div style={{ marginBottom: 20 }}>
              {t("_edit_whitelist_description")}
            </div>
            <table>
              <tr>
                <td
                  style={{ verticalAlign: "top", padding: "7px 50px 7px 0px" }}
                >
                  {" "}
                  <div>{t("_add_whitelist_domains")}</div>
                </td>
                <td
                  style={{ verticalAlign: "top", padding: "7px 50px 7px 0px" }}
                >
                  {" "}
                  <div>
                    <TextField
                      value={this.state.addInput}
                      fullWidth
                      id="addInput"
                      placeholder={"domain1.com\ndomain2.com\n..."}
                      multiline
                      rows="3"
                      // defaultValue="Default Value"
                      className={classes.textField}
                      margin="dense"
                      onChange={this.handleTextFieldChange}
                    />
                    <Button onClick={this.handleAddClicked}>{t("_add")}</Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    verticalAlign: "bottom",
                    padding: "7px 50px 7px 0px",
                  }}
                >
                  {" "}
                  <div>
                    {t("_filter_whitelist_domains")}:
                    <TextField
                      value={
                        this.state.domainFilter ? this.state.domainFilter : ""
                      }
                      style={{ width: "150px", margin: "6px 0px 0px 10px" }}
                      id="domain_filter"
                      // defaultValue="Default Value"
                      className={classes.textFieldThin}
                      margin="dense"
                      onChange={this.handleFilterChanged}
                      inputProps={{
                        className: classes.textFieldThin,
                      }}
                    />
                    <Button
                      style={{ marginLeft: 10, maxWidth: 130 }}
                      color="secondary"
                      onClick={() => this.setState({ deleteDialogOpen: true })}
                      disabled={this.state.domainFilter === null}
                    >
                      {t("_delete_all_filtered")}
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{ verticalAlign: "top", padding: "7px 50px 7px 0px" }}
                >
                  {" "}
                  <div className={classes.itemsContainer}>
                    {this.getItems()}
                  </div>
                </td>
              </tr>
            </table>

            <br />
            <LinearProgress
              className={classes.addProgress}
              color="secondary"
              style={{
                visibility: this.state.isUpdating ? "visible" : "hidden",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handlePopupClose}
              color="primary"
              disabled={this.state.isUpdating ? true : false}
            >
              {t("_close")}
            </Button>
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          open={this.state.showSuccessPopup}
          onClose={() => this.setState({ showSuccessPopup: false })}
        >
          <DialogTitle>{t("_domains_added")}</DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            {this.state.popupMessage}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ showSuccessPopup: false })}
              color="primary"
            >
              {t("_close")}
            </Button>
          </DialogActions>
        </DialogPopup>

        {this.state.deleteDialogOpen && (
          <DialogPopup
            open={this.state.deleteDialogOpen}
            onClose={() => this.setState({ deleteDialogOpen: false })}
          >
            <DialogTitle>{t("_confirm_delete")}</DialogTitle>
            <DialogContent classes={{ root: classes.contentRoot }}>
              {t("_do_you_really_want_to_delete_all_domains_in_whitelist")} (
              {
                this.state.items.filter((x) =>
                  this.state.domainFilter == null
                    ? true
                    : x.name.indexOf(this.state.domainFilter) >= 0
                ).length
              }
              )
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleBulkDeleteClicked()}
                color="secondary"
              >
                {t("_yes")}
              </Button>
              <Button
                onClick={() => this.setState({ deleteDialogOpen: false })}
                color="primary"
              >
                {t("_close")}
              </Button>
            </DialogActions>
          </DialogPopup>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(translate("translations")(WhitelistSettings)));
