import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button, Tooltip } from "@material-ui/core";

import CardImages from "../components/CardImages";
import CardNote from "../components/CardNote";
import CardInfo from "../components/CardInfo";
import CardButtons from "../components/CardButtons";
// import CardLabel from '../components/CardLabel';
import NewPanel from "@Components/panels/NewPanel";
import CasePanel from "@Components/panels/CasePanel";
import LegalPanel from "@Components/panels/LegalPanel";
import TrashPanel from "@Components/panels/TrashPanel";

import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TagInput from "@Components/TagInput";
import KeyDown from "@material-ui/icons/KeyboardArrowDown";
import KeyUp from "@material-ui/icons/KeyboardArrowUp";
import Base64 from "base-64";
import {
  addDomainsTag,
  removeDomainsTag,
  addDomainsNote,
  deleteDomainsNote,
  updateDomainsStatus,
  updateDomain,
  removeDomain,
} from "@Actions/domainsActions";

import {removeClaimDomain} from "@Actions/filterActions"
import { translate } from "react-i18next";
import { updateAllDomainTags } from "@Actions/tagsActions";
import { showSnackbar } from "@Actions/snackbarActions";

import DomainsService from "@Services/Domains";
import _ from "lodash";
import styles from "./styles";
import { getFindingsNew, getFindingsLegal } from "@Actions/findingsActions";
import FindingsService from "@Services/Findings";
import { countryCodeToFullName, isClaimFilter } from "@Utils/helpers";

class DomainCard extends Component {
  constructor(props) {
    super();

    this.actionClicked = this.actionClicked.bind(this);
  }

  state = {
    open: false,
    domainUrl: "",
    domainId: undefined,
  };

  tagsChanged = (actionName, tag) => {
    
    var data = {
      domainId: this.props.domainId,
      tag: tag,
    };

    if (actionName === "create-option") {
      DomainsService.addDomainsTag(data)
        .then((response) => {
          console.log(response);
          data.tag.id = response.id;
          this.props.actionAddDomainsTag(data);
          this.props.actionUpdateAllDomainTags(data.tag);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    } else if (actionName === "remove-option") {
      DomainsService.removeDomainsTag(data)
        .then((response) => {
          console.log(response);
          this.props.actionRemoveDomainsTag(data);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    } else {
      // select-option
      DomainsService.addDomainsTag(data)
        .then((response) => {
          console.log(response);
          data.tag.id = response.id;
          this.props.actionAddDomainsTag(data);
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    }

    return null;
  };

  handleClickOpen = () => {
    this.setState({ open: true });
    this.props.onDetailsOpened(this.props.domainId);
    document
      .getElementById(this.props.domainId)
      .scrollIntoView({ block: "center", behavior: "smooth" });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.onDetailsOpened(null);
  };

  noteAdded = (note) => {
    var data = {
      domainId: this.props.domainId,
      note: note,
    };

    DomainsService.addDomainsNote(data)
      .then((response) => {
        this.props.actionAddDomainsNote(data);
        this.props.actionShowSnackbar({
          message: this.props.t("_note_added_successfully") + "!",
          variant: "success",
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  noteDelete = (id) => {
    var data = {
      id: id,
      domainId: this.props.domainId,
    };
    DomainsService.deleteDomainNote(data)
      .then((response) => {
        this.props.actionDeleteDomainsNote(data);
        this.props.actionShowSnackbar({
          message: this.props.t("_note_deleted_successfully") + "!",
          variant: "success",
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  actionClicked(nextStatus, data) {
    if (nextStatus === "unclaim") {
      // unclaim by domain
      DomainsService.unclaimDomainByID(this.props.domainId)
        .then((res) => {
          // then update numbers

          // then remove card
          this.props.actionRemoveDomain({ domainId: this.props.domainId });
          this.props.actionRemoveClaimDomain({ id: this.props.domainId });
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });

      return;
    }

    var newData = {
      domainId: this.props.domainId,
      status: nextStatus,
      data: data,
    };

    if (newData.data === null || newData.status === "3") {
      // its not case creation

      DomainsService.updateDomainsStatus(newData)
        .then((response) => {
          this.props.actionUpdateDomainsStatus(newData);
          this.props.actionShowSnackbar({
            message: this.props.t("_domain_updated_successfully") + "!",
            variant: "success",
          });

          if (this.props.page !== "domain") {
            this.props.actionRemoveDomain({ domainId: this.props.domainId });
            this.props.actionRemoveClaimDomain({id:this.props.domainId});
          } else {
            // its domain page with the panels

            this.props.actionRemoveDomain({ domainId: this.props.domainId });
            this.props.actionRemoveClaimDomain({id:this.props.domainId});

            DomainsService.getDomain({ domainId: this.props.domainId })
              .then((response) => {
                this.props.actionuUpdateDomain({
                  domainId: this.props.domainId,
                  results: response,
                });

                var data = {
                  domainId: this.props.domainId,
                  pageNum: 1,
                };

                FindingsService.getFindingsDomain(data)
                  .then((response) => {
                    var filteredRes = _.filter(
                      response.results,
                      function (item) {
                        return item.type === "new";
                      }
                    );
                    response.info.findingCount = filteredRes.length;
                    filteredRes = _.keyBy(filteredRes, "id");
                    response.results = filteredRes;

                    this.props.actionGetFindingsNew({
                      results: response,
                      domainId: data.domainId,
                    });
                  })
                  .catch((error) => {
                    this.props.actionShowSnackbar({
                      message: this.props.t(
                        "_something_went_wrong_please_try_again_later"
                      ),
                      variant: "error",
                    });
                    console.log(error);
                  });

                FindingsService.getFindingsDomain(data)
                  .then((response) => {
                    var filteredRes = _.filter(
                      response.results,
                      function (item) {
                        return item.type === "legal";
                      }
                    );
                    response.info.findingCount = filteredRes.length;
                    filteredRes = _.keyBy(filteredRes, "id");
                    response.results = filteredRes;
                    this.props.actionGetFindingsLegal({
                      results: response,
                      domainId: data.domainId,
                    });
                  })
                  .catch((error) => {
                    this.props.actionShowSnackbar({
                      message: this.props.t(
                        "_something_went_wrong_please_try_again_later"
                      ),
                      variant: "error",
                    });
                    console.log(error);
                  });
              })
              .catch((error) => {
                this.props.actionShowSnackbar({
                  message: this.props.t(
                    "_something_went_wrong_please_try_again_later"
                  ),
                  variant: "error",
                });
                console.log(error);
              });
          }
        })
        .catch((error) => {
          this.props.actionShowSnackbar({ message: error, variant: "error" });
        });
    } else {
      // case creation
    }
  }

  populateFindings() {
    // check if we have to close details in case other details are opened

    var domainId = this.props.domainId;

    var list = [];
    list.push(<NewPanel key="new" domainId={domainId} />);

    _.forOwn(
      this.props.domainsReducer.results[domainId].cases,
      function (value, key) {
        list.push(
          <CasePanel
            domainId={domainId}
            caseId={key}
            key={key}
            pageType={"domain"}
          />
        );
      }
    );

    list.push(<LegalPanel key={domainId + "_legal"} domainId={domainId} />);
    list.push(<TrashPanel key={domainId + "_trash"} domainId={domainId} />);

    return list;
  }

  render() {
    const { classes, t } = this.props;

    var domainsReducerData =
      this.props.domainsReducer.results[this.props.domainId];

    const cardData = {
      type: "domain",
      status: domainsReducerData.info.status,
      id: this.props.domainId,
      page: this.props.page,
      newFindings: domainsReducerData.info.newFound,
      numAbortedCases: domainsReducerData.info.abortedCases,
    };

    if (
      this.props.searchReducer.search.class != null &&
      this.props.page !== "domains" &&
      this.props.page !== "search"
    ) {
      localStorage.setItem("isSearch", "1");
    }

    return (
      // <Zoom in={this.state.zoom}>
      <div className={classes.root} id={cardData.id}>
        <Card className={classes.card}>
          <div
            className={
              domainsReducerData.info.claimUser &&
              domainsReducerData.info.claimUser.length > 0 &&
              !isClaimFilter(this.props.filterReducer.currentFilterId)
                ? classes.headerBeige
                : classes.header
            }
          >
            <Tooltip
              title={domainsReducerData.info.domainId}
              placement="top-start"
              classes={{ tooltip: classes.tooltip }}
            >
              <p className={classes.headerLink}>
                {domainsReducerData.info.domainId}
              </p>
            </Tooltip>
            {domainsReducerData.info.legalAreaCountry && (
              <>
                <Tooltip
                  title={t("_legal_area")}
                  placement="top-start"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <p className={classes.headerLink}>
                    {" "}
                    | &nbsp;&nbsp;&nbsp;
                    {t(
                      "_" +
                        countryCodeToFullName(
                          domainsReducerData.info.legalAreaCountry
                        )
                    )}
                  </p>
                </Tooltip>
              </>
            )}
          </div>
          <div className={classes.details}>
            <CardContent
              className={classNames(classes.content, classes.imageContainer)}
            >
              <CardImages images={domainsReducerData.images} />
            </CardContent>
            <CardContent
              className={classNames(classes.content, classes.infoContainer)}
            >
              <CardInfo cardInfoData={domainsReducerData.info} />

              <p className={classes.cardSectionTitle}>{t("_notes")}</p>
              <CardNote
                notes={domainsReducerData.notes}
                onNoteAdded={this.noteAdded}
                onNoteDeleted={this.noteDelete}
                canAddNotes={true}
              />
              <p className={classes.cardSectionTitle}>{t("_tags")}</p>
              <TagInput
                tags={this.props.tagsReducer.domainTags}
                value={domainsReducerData.tags}
                onTagsChanged={this.tagsChanged}
                isMulti={true}
                isCreatable={true}
              />
            </CardContent>
            <CardContent
              className={classNames(classes.content, classes.buttonContainer)}
            >
              {/* <CardLabel cardData={{type: "domain", status: domainsReducerData.info.status}}/> */}
              {domainsReducerData.info.claimUser &&
              domainsReducerData.info.claimUser.length > 0 &&
              !isClaimFilter(this.props.filterReducer.currentFilterId) ? (
                <>
                  <div className={classes.claimuserContainer}>
                    <div>{t("_domain_claimed_by")}</div>
                    <div>{domainsReducerData.info.claimUser}</div>
                  </div>
                </>
              ) : (
                <>
                  <CardButtons
                    cardData={cardData}
                    onActionClicked={this.actionClicked}
                    withUnclaim={isClaimFilter(
                      this.props.filterReducer.currentFilterId
                    )}
                  />

                  {this.props.page === "domain" &&
                    this.props.searchReducer.search.class == null &&
                    _.size(this.props.domainsReducer.results) > 1 && (
                      <Link to="/domains">
                        <Button color="primary" className={classes.showMore}>
                          {t("_show_less")}
                          <KeyUp className={classes.rightIcon} />
                        </Button>
                      </Link>
                    )}
                  {this.props.searchReducer.search.class != null &&
                    this.props.page !== "domains" &&
                    this.props.page !== "search" && (
                      <Link
                        to={
                          "/search/" +
                          Base64.encode(
                            JSON.stringify(this.props.searchReducer)
                          )
                        }
                      >
                        <Button color="primary" className={classes.showMore}>
                          {t("_show_less")}
                          <KeyUp className={classes.rightIcon} />
                        </Button>
                      </Link>
                    )}
                  {(this.props.page === "domains" ||
                    this.props.page === "search") &&
                    this.props.page !== "domain" &&
                    this.props.detailsOpen === true && (
                      <Button
                        onClick={this.handleClose}
                        color="primary"
                        className={classes.showMore}
                      >
                        {t("_show_less")}
                        <KeyDown className={classes.rightIcon} />
                      </Button>
                    )}
                  {(this.props.page === "domains" ||
                    this.props.page === "search") &&
                    this.props.page !== "domain" &&
                    this.props.detailsOpen === false && (
                      <Button
                        onClick={this.handleClickOpen}
                        color="primary"
                        className={classes.showMore}
                      >
                        {t("_show_more")}
                        <KeyDown className={classes.rightIcon} />
                      </Button>
                    )}
                </>
              )}
            </CardContent>
          </div>
        </Card>
        <br />
        {this.props.detailsOpen && this.populateFindings()}
        <br />
      </div>
      // </Zoom>
    );
  }
}

DomainCard.propTypes = {
  classes: PropTypes.object.isRequired,
  domainId: PropTypes.string.isRequired,
  page: PropTypes.oneOf(["domain", "domains", "search"]).isRequired,
};

function mapStateToProps(state) {
  return {
    domainsReducer: state.domainsReducer,
    searchReducer: state.searchReducer,
    tagsReducer: state.tagsReducer,
    findingsReducer: state.findingsReducer,
    filterReducer: state.filterReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionAddDomainsTag: addDomainsTag,
      actionRemoveDomainsTag: removeDomainsTag,
      actionAddDomainsNote: addDomainsNote,
      actionDeleteDomainsNote: deleteDomainsNote,
      actionUpdateDomainsStatus: updateDomainsStatus,

      actionUpdateAllDomainTags: updateAllDomainTags,
      actionShowSnackbar: showSnackbar,

      actionuUpdateDomain: updateDomain,
      actionRemoveDomain: removeDomain,

      actionGetFindingsNew: getFindingsNew,
      actionGetFindingsLegal: getFindingsLegal,
      actionRemoveClaimDomain: removeClaimDomain
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(DomainCard)
  )
);
